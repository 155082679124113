import { Component, inject, ViewChild } from "@angular/core"
import { ListComponent } from "../../../../components/list/list.component"
import { ActionClickEvent, ListQuery, TableData, TableHeader } from "../../../../components/list/list.model"
import { SubSectionDTO, SubSectionService } from "@services/sub-section.service"
import { Router } from "@angular/router"
import { ButtonModule } from "primeng/button"
import { DialogService } from "primeng/dynamicdialog"
import { AdminSubsectionComponent } from "../../admin-subsection/admin-subsection.component"

const NB_ITEMS_PER_PAGE = 20
@Component({
  selector: "app-admin-list-subsections",
  standalone: true,
  imports: [ListComponent, ButtonModule],
  templateUrl: "./admin-list-subsections.component.html",
  styleUrl: "./admin-list-subsections.component.scss",
})
export class AdminListSubsectionsComponent {
  dialogService = inject(DialogService)
  @ViewChild("list", { static: false }) listComponent?: ListComponent<SubSectionDTO>

  loading = true
  columns: TableHeader[] = [
    // { field: "title", header: "Nom officiel", sort: true, sortField: "title" },
    { field: "title_internal", header: "Nom interne", sort: true, sortField: "title_internal" },
    { field: "category_label", header: "Catégorie", sort: true, sortField: "category_label" },
    { field: "versions_label", header: "Versions", sort: true, sortField: "versions_label" },
  ]
  dataItems: TableData<SubSectionDTO> = {
    headers: this.columns,
    values: [],
    actions: [
      {
        id: "EDIT",
        icon: "pi-pencil",
        label: "Editer la sous section",
      },
    ],
    totalRecords: 0,
    pageNumber: 0,
  }
  listHeight = `${window.innerHeight - 360}px`

  constructor(private subSectionService: SubSectionService, private router: Router) {}

  ngOnInit(): void {}

  fetchMore(): void {
    this.subSectionService.getMore(NB_ITEMS_PER_PAGE).then((data) => {
      this.dataItems.values = this.dataItems.values.concat(data)
    })
  }

  fetchData(params: ListQuery): void {
    this.loading = true
    if (params.sorts || params.search) {
      this.subSectionService.filter(params.search, params.sorts && params.sorts[0]).then((items) => {
        this.dataItems.values = items
        this.loading = false
      })
    } else {
      this.subSectionService.getAll(NB_ITEMS_PER_PAGE).then((items) => {
        this.dataItems.values = items
        // this.updateProgram(items[0])
        this.loading = false
      })
    }
  }

  actionClick(event: ActionClickEvent<SubSectionDTO>): void {
    switch (event.actionId) {
      case "EDIT":
        this.updateSubsection(event.item)
        break
    }
  }

  createSubsection(): void {
    this.dialogService
      .open(AdminSubsectionComponent, {
        header: "Créer une sous section",
        width: "98vw",
        data: {},
      })
      .onClose.subscribe((subSection) => {
        if (subSection) {
          this.dataItems.values.unshift(subSection)
          if (this.listComponent) {
            this.listComponent.selectedRow = subSection.id
          }
        }
      })
  }

  updateSubsection(subSection: SubSectionDTO): void {
    this.dialogService
      .open(AdminSubsectionComponent, {
        header: `Modification de la sous-section '${subSection.title_internal}'`,
        width: "98vw",
        data: {
          id: subSection.id,
        },
      })
      .onClose.subscribe((subSection) => {
        if (subSection) {
          const toUpdate = this.dataItems.values.find((x) => x.id === subSection.id)
          if (toUpdate) {
            Object.assign(toUpdate, subSection)
          }
          if (this.listComponent) {
            this.listComponent.selectedRow = subSection.id
          }
        }
      })
  }
}
