import { Component, inject, Input } from "@angular/core"
import { ProgramPublishedService, SectionPubDTO } from "@services/program.published.service"
import { SubSectionComponent } from "../sub-section/sub-section.component"

@Component({
  selector: "app-section",
  standalone: true,
  imports: [SubSectionComponent],
  templateUrl: "./section.component.html",
  styleUrl: "./section.component.scss",
})
export class SectionComponent {
  programPubService = inject(ProgramPublishedService)
  @Input() section: SectionPubDTO | undefined

  showTitle(): boolean {
    const currentSubsection = this.programPubService.getCurrentSubSection()
    const currentSection = this.programPubService.getCurrentSection()
    return currentSection?.subSections[0]?.id === currentSubsection?.id
  }
}
