@if (_section) {

  <div class="container mb-1 relative">
    <div class="grid-section">
      <div class="flex align-items-center">Nom</div>
      <input required [(ngModel)]="_section.title"
             pInputText id="title"/>
      <div class="flex align-items-center ml-5">Nom interne</div>
      <input required [(ngModel)]="_section.title_internal"
             pInputText/>
    </div>
    <div>
      <p-multiSelect
        [options]="_subSections"
        placeholder="Select Cities"
        scrollHeight="600px"
        appendTo="body"
        [showClear]="true">
        <ng-template let-sub pTemplate="item">
          <div class="flex align-items-center gap-2">
            <div>({{ sub.title }})</div>
          </div>
        </ng-template>
        <ng-template let-value pTemplate="selectedItems">
          @if (value?.length > 4) {
            <div class="inline-flex align-items-center">
              {{value.length}} sous séctions sélectionnées
            </div>
          } @else {
            @for (option of value; track option.id) {
              <div class="inline-flex align-items-center">
                <div>{{ option.title }},</div>
              </div>
            }
          }
        </ng-template>
      </p-multiSelect>
      @for (refs of _section.subsectionsRefs; track refs.id) {
        <div>{{ refs.id }}</div>
      }
    </div>
    <!--      <p-dropdown-->
    <!--        [(ngModel)]=currentVersionId-->
    <!--        [options]="_section. s.versions"-->
    <!--        placeholder="Selectionner une version"-->
    <!--        optionValue="id"-->
    <!--        optionLabel="title"-->
    <!--        [showClear]="true"-->
    <!--        (onChange)="versionChange()"-->
    <!--      ></p-dropdown>-->
  </div>
}
