import { inject, Injectable } from "@angular/core"
import { FireStoreService } from "@services/fire-store.service"
import { REFERENTIAL, ReferentialDTO, ReferentialService } from "@pages/admin/referential/referential.service"
import { arrayUnion } from "@angular/fire/firestore"

export interface SubVersionDTO {
  id: string
  title: string
  content: string
  date: Date
  layout?: any
}

export interface SubSectionDTO {
  id?: string
  date: Date

  category?: { id: string }
  title_internal: string

  title: string
  content: string

  versions: Array<SubVersionDTO>

  versions_label?: string // generated
  category_label?: string // generated
}

@Injectable({
  providedIn: "root",
})
export class SubSectionService extends FireStoreService<SubSectionDTO> {
  refService = inject(ReferentialService)

  constructor() {
    super()
    this.init({
      collectionKey: `sub-section`,
      fullTextFields: ["title", "title_internal"], // FIXME Alban
    })
  }

  override toDTO(dto: SubSectionDTO, action: string): Promise<SubSectionDTO> {
    return Promise.all([this.refService.getAllFromCache(REFERENTIAL.category_subsection)]).then((refs) => {
      const [refCategory] = refs
      dto.category_label = refCategory.find((x: ReferentialDTO) => x.id === dto.category?.id)?.name || "-"

      dto.versions_label =
        (dto.versions?.length > 0 ? `[${dto.versions?.length}] ` : "") +
        (dto.versions?.map((v) => v.title).join(", ") || "")

      return dto
    })
  }

  createVersion(subSectionId: string, version: SubVersionDTO): Promise<SubVersionDTO> {
    version.id = this.uuid()
    return this.firestore
      .collection(this.config.collectionKey)
      .doc(subSectionId)
      .set({ versions: arrayUnion(version) }, { merge: true })
      .then(() => version.id)
      .catch((err) => this.handleError(err, ""))
  }

  async migrateSubSection() {
    const all = await this.getAllFromCache()

    console.log("aaaa", all)
    const res = await Promise.all(
      all.map(async (sub) => {
        if (sub.content && (!sub.versions || sub.versions.length === 0)) {
          sub.versions = [
            {
              id: this.uuid(),
              title: sub.title,
              content: sub.content,
              date: new Date(),
            },
          ]
          //@ts-ignore
          delete sub["title"]
          //@ts-ignore
          delete sub["content"]
        }
        await this.update(sub)
        return sub
      })
    )

    console.log("aaaaabbbbbb", res)
  }
}
