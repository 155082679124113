import { inject, Injectable } from "@angular/core"
import { FireStoreService } from "@services/fire-store.service"
import { doc, onSnapshot } from "@angular/fire/firestore"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { ActivatedRoute, Params, Router } from "@angular/router"
import { MenuItem } from "primeng/api"
import { environment } from "../../environments/environment"

export interface SectionPubDTO {
  id: string
  title: string
  subSections: Array<SubSectionPubDTO>
}

export interface SubSectionPubDTO {
  id: string

  title: string
  content: string
}

export interface ProgramPubDTO {
  id?: string
  version: string

  disabledMessage: string
  date_published: Date
  accessible: boolean
  name: string
  town: string
  date: string
  date_real?: Date
  intro: SubSectionPubDTO

  sections: Array<SectionPubDTO> // private => connected for accessing
}

@Injectable({
  providedIn: "root",
})
export class ProgramPublishedService extends FireStoreService<ProgramPubDTO> {
  angularFirestore = inject(AngularFirestore)
  router = inject(Router)
  activatedRoute = inject(ActivatedRoute)

  public summaryItems: MenuItem[] = []
  public currentProgram: ProgramPubDTO | undefined
  public currentSubSectionIndex = 0
  public currentSubSections: Array<SubSectionPubDTO> = []

  constructor() {
    super()
    this.init({
      collectionKey: `program-published`,
      fullTextFields: [],
    })
    this._init()
  }

  private async _init() {
    const prog = await this.getCurrentProgramNotConnected()
    const currentProgId = prog?.id || ""
    if (currentProgId) {
      console.log("listening to program-published", currentProgId)
      let first2 = true
      onSnapshot(doc(this.angularFirestore.firestore, "program-published", currentProgId), (snapshot) => {
        if (first2 || !this.currentProgram) {
          // ignoring the first update (during init)
          first2 = false
          return
        }
        const route = this.router.url || ""

        if (route.includes("program") && !route.includes("admin")) {
          const prog = snapshot.data() as any
          console.log("Program updated!", prog)
          if (prog && prog.accessible === false && this.currentProgram?.accessible) {
            this.currentProgram.accessible = false
            this.currentProgram.disabledMessage = prog.disabledMessage
            this.notifService.displayInfo("Le programme est devenu inaccessible")
          } else if (!this.currentProgram.accessible && prog.accessible === true) {
            this.currentProgram.accessible = true
            this.notifService.displayInfo("Le programme est de nouveau accessible")
          } else if (prog.accessible) {
            this.notifService.displaySuccess("Le programme vient d'être mis à jour à l'instant.")
            setTimeout(() => {
              this.notifService.displayInfo("Rechargez pour actualiser.")
            }, 2000)
          }
        }
      })
    }
  }

  async initProgramWithSection(programId: string, subId = ""): Promise<any> {
    const program = await this.get(programId)
    const sections = await this.getSections(programId)
    program.sections = sections || []

    this.currentProgram = program
    this.currentSubSections = []
    this.currentProgram?.sections.forEach((section) => {
      section?.subSections.forEach((sub) => {
        this.currentSubSections.push(sub)
      })
    })
    this.currentSubSectionIndex = 0
    if (subId) {
      const index = this.currentSubSections.findIndex((s) => s.id === subId)
      if (index >= 0) this.currentSubSectionIndex = index
    }
    this.buildSummaryItems()
    await this.updateQueryParams()
  }

  async updateQueryParams() {
    const index = this.currentSubSectionIndex
    if (index >= 0 && index < this.currentSubSections.length) {
      const sub = this.currentSubSections[index]
      const subId = sub?.id
      const queryParams: Params = { id: subId }
      await this.router.navigate([], {
        relativeTo: this.activatedRoute,
        queryParams,
        replaceUrl: true,
      })
      if (environment.production)
        gtag("event", "page_view", {
          page_title: sub?.title || sub?.id || "Unknown",
          page_location: window.location.pathname + window.location.search,
        })
      console.log("gtag", {
        page_title: sub?.title || sub?.id || "Unknown",
        page_location: window.location.pathname + window.location.search,
      })
    }
    this.updateSummarySelected()
  }

  updateSummarySelected() {
    const index = this.currentSubSectionIndex
    if (index >= 0 && index < this.currentSubSections.length) {
      const sub = this.currentSubSections[index]
      this.summaryItems.forEach((s) => (s.styleClass = s.styleClass?.replace(/ selected/g, "")))
      const arr = this.summaryItems.filter((s) => s.id === sub.id && s.label)
      const sum = arr.length > 0 ? arr[arr.length - 1] : undefined
      if (sum) {
        sum.styleClass += " selected"
      }
    }
  }

  buildSummaryItems() {
    let index = 0
    this.summaryItems = [
      {
        label: "", //"SOMMAIRE",
        styleClass: "menu-section-summary",
        command: () => {},
      },
    ]
    this.currentProgram?.sections.forEach((s) => {
      const theIndex = index
      this.summaryItems.push({
        id: s?.subSections[0]?.id || s.id,
        label: s.title,
        styleClass: "menu-section",
        command: () => {
          if (this.currentSubSectionIndex !== theIndex) {
            this.currentSubSectionIndex = theIndex
            this.updateQueryParams()
            this.scrollTop()
          }
        },
      })

      s.subSections.forEach((sub) => {
        const theIndex2 = index
        if (s.subSections.length > 1 && sub.title)
          this.summaryItems.push({
            id: sub.id,
            label: sub.title,
            styleClass: "",
            command: () => {
              if (this.currentSubSectionIndex !== theIndex2) {
                this.currentSubSectionIndex = theIndex2
                this.updateQueryParams()
                this.scrollTop()
              }
            },
          })
        index++
      })

      this.summaryItems.push({
        label: "",
        styleClass: "separator",
        command: () => {},
      })
      // this.summaryItems.push({ separator: true })
    })
  }

  async saveCurrentProgramId(id: string): Promise<any> {
    return this.firestore
      .collection(`utils`)
      .doc("currentProgramId")
      .set({ id })
      .catch((err) => this.handleError(err, null))
  }

  async getCurrentProgramNotConnected(): Promise<ProgramPubDTO | undefined> {
    const id = await this.getCurrentProgramId()
    if (id) {
      return await this.get(id)
    }
    return
  }

  private getCurrentProgramId(): Promise<string> {
    return this.firestore
      .collection(`utils`)
      .doc("currentProgramId")
      .get()
      .toPromise()
      .then((res: any) => {
        const data = res.data && res.data()
        return data?.id || "id"
      })
      .catch((err) => this.handleError(err, null))
  }

  async getCurrentProgram(): Promise<ProgramPubDTO | undefined> {
    return this.currentProgram
  }

  getCurrentSection(): SectionPubDTO | undefined {
    const currentSubsection = this.getCurrentSubSection()
    if (currentSubsection && this.currentProgram) {
      return this.currentProgram.sections.find((sec) => sec.subSections?.find((sub) => sub.id === currentSubsection.id))
    }
    return
  }

  getCurrentSubSection(): SubSectionPubDTO | undefined {
    if (this.currentSubSections && this.currentSubSectionIndex < this.currentSubSections.length) {
      return this.currentSubSections[this.currentSubSectionIndex]
    }
    return
  }

  prev() {
    if (this.currentSubSectionIndex > 0) {
      this.currentSubSectionIndex--
      this.updateQueryParams()
    }
  }

  next() {
    if (this.currentSubSectionIndex < this.currentSubSections.length - 1) {
      this.currentSubSectionIndex++
      this.updateQueryParams()
    }
  }

  getSections(programId: string): Promise<Array<SectionPubDTO>> {
    return this.firestore
      .collection(`${this.config.collectionKey}/${programId}/private`)
      .doc("sections")
      .get()
      .toPromise()
      .then((raw: any) => {
        return raw.data()?.sections
      })
      .catch((err) => this.handleError(err, null))
  }

  addSection(programId: string, sections: Array<SectionPubDTO>): Promise<any> {
    return this.firestore
      .collection(`${this.config.collectionKey}/${programId}/private`)
      .doc("sections")
      .set({ sections })
      .catch((err) => this.handleError(err, null))
  }

  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
    })
  }
}
