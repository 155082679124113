import { ApplicationConfig, importProvidersFrom } from "@angular/core"
import { provideRouter } from "@angular/router"

import { routes } from "./app.routes"
import { AngularFireModule } from "@angular/fire/compat"
import { environment } from "../environments/environment"
import { ConfirmationService, MessageService } from "primeng/api"
import { provideAnimations } from "@angular/platform-browser/animations"
import { DialogService } from "primeng/dynamicdialog"

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    importProvidersFrom(AngularFireModule.initializeApp(environment.firebaseConfig)),
    MessageService as any,
    DialogService as any,
    ConfirmationService as any,
  ],
}
