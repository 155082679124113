import { Injectable } from "@angular/core"
import { AngularFirestore, DocumentReference } from "@angular/fire/compat/firestore"
import { TableHeader } from "../../../components/list/list.model"
import { FireStoreService } from "@services/fire-store.service"

export enum REFERENTIAL {
  category_subsection = "ref-category-subsection",
}

@Injectable({
  providedIn: "root",
})
export class ReferentialService {
  fireStoreService: FireStoreService<any>

  refs = {
    [REFERENTIAL.category_subsection]: "Catégories sous-sections",
  }

  constructor(private firestore: AngularFirestore, private refCategorySubSectionService: RefCategorySubSectionService) {
    this.fireStoreService = this.refCategorySubSectionService
  }

  getColumns(): TableHeader[] {
    return [{ field: "name", header: "Valeur", sort: true, sortField: "name" }].concat(
      this.fireStoreService.config.fields || []
    )
  }

  getReferentials(): Array<{ name: string; value: string }> {
    return Object.keys(this.refs).map((key) => ({ name: (this.refs as any)[key], value: key }))
  }

  getReferentialLabel(key: REFERENTIAL): string {
    return this.refs[key]
  }

  getService(key: REFERENTIAL): FireStoreService<any> {
    switch (key) {
      case REFERENTIAL.category_subsection:
        return this.refCategorySubSectionService
      default:
        throw "REFERENTIAL UNKNOWN - " + key
    }
  }

  changeService(key: REFERENTIAL): void {
    this.fireStoreService = this.getService(key)
  }

  getAllFromCache(key: REFERENTIAL): Promise<Array<ReferentialDTO>> {
    return this.getService(key).getAllFromCache()
  }

  getRefFromId(key: REFERENTIAL, id: string): DocumentReference<unknown> {
    const collectionKey = this.getService(key).config.collectionKey
    return this.firestore.doc(`${collectionKey}/${id}`).ref
  }
}

export interface ReferentialDTO {
  id: string
  name?: string
  color?: string
  distribution?: boolean
  value?: string
}

@Injectable({
  providedIn: "root",
})
class RefCategorySubSectionService extends FireStoreService<ReferentialDTO> {
  constructor() {
    super()
    this.init({
      collectionKey: REFERENTIAL.category_subsection,
      fullTextFields: ["name"],
    })
  }
}
