<div>
  <div (click)="visible = true" class="cursor-pointer flex align-items-center">
    <i class="pi pi-bars" style="font-size:1.5rem"></i>
    <div class="ml-2" style="font-size:12px">SOMMAIRE</div>
  </div>
  <p-dialog [(visible)]="visible" [style]="{width: '90vw', height: '90vh'}" [modal]="true" styleClass="overlaypanelmenu2">
    <app-info-detail [info]="programPubService.currentProgram" [big]="false"></app-info-detail>
    @for (item of programPubService.summaryItems; track item.id) {
      <div [class]="item.styleClass + ' itemm'" (click)="item?.command($any($event));visible = false">
        {{ item.label }}
      </div>
    }

    <div class="mt-6 ml-3 cursor-pointer" (click)="signOut()">
      <i class="pi pi-sign-out mr-2"></i>
      <span>Me déconnecter</span>
    </div>
  </p-dialog>
</div>
