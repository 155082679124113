<div class="container" [class]="{'loading' : !loaded}">
  <div class="header"></div>

  <app-info-detail [info]="program"></app-info-detail>

  @if (!sent) {
    <app-sub-section [subSection]="program?.intro"></app-sub-section>

    <div class="flex flex-column gap-2 mt-6 mb-3">
      <label for="email_label" class="white">Addresse email</label>
      <input type="email" [(ngModel)]="email"
             pInputText id="email_label" #email_model="ngModel" [email]="true" required/>
    </div>

    @if (email_model.invalid && (email_model.dirty || email_model.touched)) {
      <div class="text-red-700">Merci de renseigner un email valide</div>
    } @else {
      <div>&nbsp;</div>
    }

    <div class="btn-send mt-4 p-3 text-center" (click)="send()"
         [class]="{'disabled': email_model.invalid || submitting}">
      Reçevoir mon lien vers le programme numérique
    </div>
    @if (submitting) {
      <div class="text-center mt-4">
      <p-progressSpinner styleClass="w-2rem h-2rem mr-3"></p-progressSpinner>
      </div>
    }

    @if (currentUser?.email) {
      <div class="flex flex-column align-items-center mt-4">
        <div style="color:red;"> Vous êtes connecté avec
          <b style="color:red;">{{ currentUser?.email }}</b></div>
        <div class="mt-2">
          <p-button (click)="goToProgram()">Accéder au programme</p-button>
        </div>
        <div class="mt-3 cursor-pointer">
          <div (click)="signOut()" class="underline">Me déconnecter</div>
        </div>
      </div>
    }
  } @else {
    <div class="label-sent mt-4">
      <p>
      Un lien vous a été envoyé à l'adresse <b>{{  email }}</b>
      </p>
      <p>
      Merci de le suivre pour accéder à votre programme numérique.
      </p>
      <p><i>Si vous n'avez rien reçu, pensez à regarder dans vos messages indésirables</i>
      </p>
    </div>
  }
</div>
