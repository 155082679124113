import { Component, Input } from "@angular/core"
import { SubSectionPubDTO } from "@services/program.published.service"

@Component({
  selector: "app-sub-section",
  standalone: true,
  imports: [],
  templateUrl: "./sub-section.component.html",
  styleUrl: "./sub-section.component.scss",
})
export class SubSectionComponent {
  @Input() subSection: SubSectionPubDTO | undefined
}
