import { inject, Injectable } from "@angular/core"
import { AngularFirestore, DocumentReference, DocumentSnapshot } from "@angular/fire/compat/firestore"
import { AngularFireFunctions } from "@angular/fire/compat/functions"
import { NotifService } from "@services/notif.service"
import { ListSortParameters } from "../components/list/list.model"
import { FilterMetadata, FilterService } from "primeng/api"
import { Utils } from "@services/utils"

@Injectable({
  providedIn: "root",
})
export abstract class FireStoreService<T> {
  static STARTED = false
  static ACTION = {
    ALL: "all",
    ALL_PAGINATED: "all_paginated",
    GET: "get",
    CREATE: "create",
    UPDATE: "update",
    DELETE: "delete",
    NONE: "none",
  }

  startAfterPagination?: DocumentSnapshot<any>
  cache: { [key: string]: T } = {}
  cachePopulated = false
  cachePopulating = false
  cacheEnabled = true

  config: Config = {
    collectionKey: "",
    fullTextFields: [],
    sorting: {
      field: "",
      order: "asc",
    },
  }

  functions: AngularFireFunctions = inject(AngularFireFunctions)
  notifService: NotifService = inject(NotifService)
  filterService: FilterService = inject(FilterService)
  firestore: AngularFirestore = inject(AngularFirestore)

  // a = connectFunctionsEmulator(getFunctions(), "localhost", 5003) // for debugging cloudfunction locally

  protected constructor() {
    if (!FireStoreService.STARTED) {
      this.firestore.firestore.settings({ ignoreUndefinedProperties: true })
      FireStoreService.STARTED = true
    }
    const primeNgFilterService = this.filterService.filters as any
    if (!primeNgFilterService.multi) {
      // this.filterService.register?
      primeNgFilterService.multi = (filter = "", filterValue: string[]): boolean => {
        filter = this._formatFullText(filter)
        filterValue = filterValue || []
        return filterValue.filter((x) => filter.includes(this._formatFullText(x))).length === filterValue.length
      }
    }

    const now = Date.now() - 24 * 60 * 60 * 1000
    primeNgFilterService.dateBeforeNow = (value: any, filter: any): boolean => value.getTime() < now
    primeNgFilterService.dateAfterNow = (value: any, filter: any): boolean => value.getTime() > now
  }

  protected callCloudFunction(
    cb: () => Promise<any>,
    cacheKey = "",
    storeInLocalStorage = false,
    hydratingCb?: (data: any) => Promise<any>
  ) {
    const callingCF = () => {
      console.time(cacheKey)
      console.log(`calling cloud function '${cacheKey}'`)
      return cb().then((data) => {
        this._setCache(cacheKey, data)
        if (storeInLocalStorage) {
          localStorage[cacheKey] = JSON.stringify(data)
        }
        console.timeEnd(cacheKey)
        return data
      })
    }

    if (cacheKey) {
      let cacheValue = this._getFromCache(cacheKey)
      if (!cacheValue && storeInLocalStorage) {
        try {
          cacheValue = JSON.parse(localStorage[cacheKey])
          if (cacheValue) {
            callingCF().then((newData) => {
              if (hydratingCb) {
                hydratingCb(newData)
              }
            })
          }
        } catch (e) {}
      } else {
        if (hydratingCb) {
          hydratingCb(null)
        }
      }
      if (cacheValue) return Promise.resolve(cacheValue)
    } else {
      throw new Error("CacheKey should not be null")
    }

    return callingCF()
  }

  init(config: Config): void {
    if (this.config.collectionKey !== config.collectionKey) {
      this._resetCache()
    }
    this.config = config
    if (localStorage["caching"] || navigator.onLine === false) {
      // localforage.getItem(this.config.collectionKey).then((values: any) => {
      //   if (values) {
      //     this.revivingFirestoreData(values) // redefining path and id getters
      //     const cache = {}
      //     values.forEach((v: any) => (cache[v?.id || ""] = v))
      //     this.cache = cache as any
      //     this.cachePopulated = true
      //   }
      // })
    }
  }

  invalidateCache(): void {
    this._resetCache()
  }

  protected _resetCache(): void {
    this.cache = {}
    this.cachePopulated = false
    this.cachePopulating = false
    ;(window as any)._flowCache = (window as any)._flowCache || {}
    ;(window as any)._flowCache[this.config.collectionKey || "X"] = this.cache
  }

  protected _updateCache(key: string, item: T /* !!partial object!! */, merging = false): void {
    const id = (item as any)?.id
    if (!id) {
      return
    }
    if (merging && this.cache[id]) {
      Object.assign(this.cache[id] as any, item)
      // console.log(this.cache[id], item)
    } else {
      this.cache[id] = { ...(item as any) } // cloning avoiding side effects... ;)
    }
  }

  private _deleteFromCache(key: string, id: string): void {
    delete this.cache[id]
  }

  protected _setCache(id: string, obj: any) {
    this.cache[id] = obj
  }

  protected _getFromCache(id: string): T {
    return this.cache[id]
  }

  protected _toDTO(raw: any, action: string): Promise<T> {
    const dto = raw.data() as T
    for (let dtoKey in dto) {
      const value = dto[dtoKey] as any
      if (typeof value === "object" && dtoKey.includes("date") && value?.seconds) {
        // @ts-ignore
        dto[dtoKey] = new Date(value.seconds * 1000) // the type "timestamp" from firestore is not standard
      }
      // // Managing firestore references
      // if (value && value.firestore && value.path) {
      //   if (this.cacheReferences[value.path]) {
      //     dto[dtoKey + "_label"] = this.cacheReferences[value.path]
      //   } else {
      //     promises.push(
      //       value.get().then((x: any) => {
      //         dto[dtoKey + "_label"] = x.data()?.name || ""
      //         this.cacheReferences[value.path] = dto[dtoKey + "_label"]
      //       })
      //     )
      //   }
      // }
    }

    return dto
      ? this.toDTO(
          {
            ...dto,
            id: raw.id,
          },
          action
        )
      : Promise.resolve(null)
  }

  protected _getAllPaginated(
    collectionKey: string,
    limit?: number,
    startAfter?: DocumentSnapshot<any>
  ): Promise<Array<T>> {
    return this.firestore
      .collection(collectionKey, (ref) => {
        let ref2 = limit ? ref.limit(limit) : ref
        ref2 = this.config.sorting ? ref2.orderBy(this.config.sorting.field, this.config.sorting.order) : ref2
        return startAfter ? ref2.startAfter(startAfter) : ref2
      })
      .get()
      .toPromise()
      .then((res) => {
        const items: Array<Promise<T>> = []
        res?.forEach((r) => {
          if (!(r.data() as any).deleted) {
            items.push(this._toDTO(r, limit ? FireStoreService.ACTION.ALL_PAGINATED : FireStoreService.ACTION.ALL))
            if (limit !== undefined) this.startAfterPagination = r as any
          }
        })
        console.log(`!QUOTA! firestore reading - _getAllPaginated ${collectionKey}`, items.length)
        return Promise.all(items)
      })
      .catch((err) => this.handleError(err, []))
  }

  waitUntilCachePopulated(log: string): Promise<any> {
    if (this.cachePopulating) {
      // console.log(`Waiting for cache (${log}) ! ${this.config.collectionKey}`)
      let inter: any
      let timeout: any
      let count = 0
      return new Promise<void>((resolve) => {
        // timeout = setTimeout(() => resolve(), 4000)
        inter = setInterval(() => {
          count++
          if (this.cachePopulated) {
            // console.log("inter", count)
            resolve()
          }
        }, 200)
      }).finally(() => {
        clearTimeout(timeout)
        clearInterval(inter)
      })
    }
    return Promise.resolve()
  }

  get(id: string, fromCache = false, throwingError = true): Promise<T> {
    const tryFromCache = fromCache
      ? this.waitUntilCachePopulated("get").then(() => this._getFromCache(id))
      : Promise.resolve()

    return tryFromCache.then((valueFromCache) => {
      return (
        valueFromCache ||
        this.firestore
          .collection(this.config.collectionKey)
          .doc(id)
          .get()
          .toPromise()
          .catch((err) => {
            if (throwingError) throw err
            else {
              return {
                id,
                data: () => ({}),
              }
            }
          })
          .then((res) => {
            if (!valueFromCache) console.log(`!QUOTA! firestore reading - get ${this.config.collectionKey} - ${id}`, 1)
            return this._toDTO(res, FireStoreService.ACTION.GET).then((dto) => {
              this._updateCache(this.config.collectionKey, dto)
              return dto
            })
          })
          .catch((err) => this.handleError(err, null))
      )
    })
  }

  getAllFromCache(): Promise<Array<T>> {
    const collectionKey = this.config.collectionKey //fixing the value because it can be updated afterward!!
    let wait = Promise.resolve()
    if (!this.cachePopulated && this.cacheEnabled) {
      if (this.cachePopulating) {
        wait = this.waitUntilCachePopulated("getAllFromCache")
      }
      this.cachePopulating = true
    }
    return wait.then(() =>
      this.cachePopulated && this.cacheEnabled
        ? Promise.resolve(Object.values(this.cache))
        : this._getAllPaginated(collectionKey).then((all) => {
            this._resetCache()
            all.map((y) => this._updateCache(collectionKey, y))
            this.cachePopulated = true
            this.cachePopulating = false
            this.prepareSerializingFirestoreData(all)
            // localforage.setItem(collectionKey, all).catch((err) => {
            //   console.error(collectionKey, err)
            // })
            return all
          })
    )
  }

  getAll(limit?: number): Promise<Array<T>> {
    return this._getAllPaginated(this.config.collectionKey, limit)
  }

  getMore(limit?: number): Promise<Array<T>> {
    return this._getAllPaginated(this.config.collectionKey, limit, this.startAfterPagination)
  }

  create(cmd: any, id?: string): Promise<T> {
    cmd = this._beforeSavingHook(cmd)
    return (
      id
        ? this.firestore
            .collection(this.config.collectionKey)
            .doc(id)
            .get()
            .toPromise()
            .then((x) => {
              const existing = !!x?.data()
              if (existing) {
                throw new Error(`Impossible de créer l'entité car l'id '${id}' est déjà utilisé.`)
              }
              return this.firestore
                .collection(this.config.collectionKey)
                .doc(id)
                .set({ ...cmd }, { merge: false })
            })
        : this.firestore.collection(this.config.collectionKey).add({ ...cmd })
    )
      .then((res: any) => {
        return this.toDTO({ ...cmd, id: id || res?.id }, FireStoreService.ACTION.CREATE)
      })
      .then((item: any) => {
        this._updateCache(this.config.collectionKey, item)
        return item
      })
      .catch((err) => this.handleError(err, null))
  }

  private _beforeSavingHook(dto: any): any {
    // called before serializing to firestore
    this._prepareSerializedDataToFirestore(dto)
    return this.beforeSavingHook(dto)
  }

  // TO BE overwrite
  protected beforeSavingHook(dto: any): any {
    return dto
  }

  // TO BE overwrite (see performances service)
  protected toDTO(dto: any, action: string): Promise<any> {
    // called after deserializing from firestore
    return Promise.resolve(dto)
  }

  update(cmd: any /*partial object*/): Promise<T> {
    cmd = this._beforeSavingHook(cmd)
    const { id, ...cmdNoId } = cmd
    return this.firestore
      .collection(this.config.collectionKey)
      .doc(cmd.id)
      .update(cmdNoId)
      .then((res) => {
        const item = { ...cmd } as T
        const cache = this._getFromCache(cmd.id) || {}
        const newItem = { ...cache }
        Object.assign(newItem, item) // as item could be partial, retrieve all properties before calling toDTO
        return this.toDTO(newItem, FireStoreService.ACTION.UPDATE)
      })
      .then((item) => {
        this._updateCache(this.config.collectionKey, item, true)
        return item
      })
      .catch((err) => this.handleError(err, null))
  }

  delete(id: string, softDelete = false): Promise<void> {
    return (
      softDelete
        ? this.firestore.collection(this.config.collectionKey).doc(id).update({ deleted: true })
        : this.firestore.collection(this.config.collectionKey).doc(id).delete()
    )
      .then((res) => this._deleteFromCache(this.config.collectionKey, id))
      .catch((err) => this.handleError(err, null))
  }

  filter(search?: string, sort?: ListSortParameters, filters?: FilterMetadata): Promise<Array<T>> {
    return this.getAllFromCache().then((items) => {
      if (search) {
        const search2 = this._formatFullText(search)
        items = items.filter(
          (x: any) => this.config.fullTextFields.filter((y) => this._formatFullText(x[y]).includes(search2)).length > 0
        )
      }
      items = this.sortingAndFilteringItems(items, sort, filters)
      return items
    })
  }

  parsingFilters(stateString: string): any {
    // https://github.com/primefaces/primeng/blob/master/src/app/components/table/table.ts#L2046
    const dateFormat = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/
    const reviver = (key: any, value: any) => {
      if (typeof value === "string" && dateFormat.test(value)) {
        return new Date(value)
      }

      return value
    }
    return JSON.parse(stateString, reviver)
  }

  sortingAndFilteringItems(items: T[], sort?: ListSortParameters, filters?: FilterMetadata): T[] {
    if (sort) {
      items.sort((a, b) => {
        const order = sort.sortOrder ? 1 : -1
        //@ts-ignore
        let valueA = a[sort.fieldToSort] as any
        //@ts-ignore
        let valueB = b[sort.fieldToSort] as any
        if (!valueA) {
          return 1
        }
        if (!valueB) {
          return -1
        }
        if (typeof valueA === "string") {
          valueA = Utils.formatStringFullText(valueA)
          valueB = Utils.formatStringFullText(valueB)
        }
        return valueA <= valueB ? order : -order
      })
    }
    if (filters) {
      //@ts-ignore
      delete filters["global"]
      const items2: T[] = []
      items.forEach((item) => {
        let match = true

        for (const key in filters) {
          //@ts-ignore
          const filter = filters[key]
          //@ts-ignore
          let itemKey = item[key]
          if (filter?.value && Array.isArray(filter.value)) {
            // filtering with multiselect
            if (key.includes("_label")) {
              // using referential id for filtering and label for displaying
              const key2 = key.replace("_label", "")
              //@ts-ignore
              itemKey = item[key2]?.id
            }
          }

          if (!this.filterService.filters[filter.matchMode](itemKey, filter.value)) {
            match = false
            break
          }
        }
        if (match) {
          items2.push(item)
        }
      })
      items = items2
    }
    return items
  }

  handleError(error: any, ret: any): any {
    if (error?.code === "permission-denied") {
      this.notifService.displayWarning(
        `Vous n'avez pas les droits, tentez de vous reconnecter. (${this.config.collectionKey})`
      )
      // https://firebase.google.com/docs/auth/admin/verify-id-tokens#web
      // window.location.href = "login"
      // console.trace()
      console.error("error permission", this.config, error)

      return ret
    }

    this.notifService.displayError(`Firestore: ${error?.message}` || "Une erreur firestore s'est produite.")
    console.error(error)
    return ret
  }

  private _formatFullText(str: string): string {
    if (!str?.toLocaleLowerCase) return ""
    return (str || "")
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
  }

  public uuid(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8
      return v.toString(16)
    })
  }

  exportAllToJson(): any {
    this._resetCache() // otherwise the backup is sometimes not working properly :(
    return this.getAllFromCache().then((objects) => {
      this.prepareSerializingFirestoreData(objects)
      return objects
    })
  }

  /**
   Restoring firestore data from serialized data in order to being able to save data into firestore
   **/
  _prepareSerializedDataToFirestore(obj2: any, key2 = ""): any {
    const obj = key2 ? obj2[key2] : obj2
    if (Array.isArray(obj)) {
      for (const el of obj) {
        this._prepareSerializedDataToFirestore(el)
      }
    } else if (typeof obj === "object") {
      if (obj)
        if (obj["firestore"] && obj["_delegate"]) {
        } else if (obj["firestore"] === true && obj["path2"]) {
          obj2[key2] = this.firestore.doc(obj["path2"]).ref
        } else {
          for (const key in obj) this._prepareSerializedDataToFirestore(obj, key)
        }
    }
  }

  /**
   Removing useless firestore data for being able to store data in cache (indexedDB)
   **/
  prepareSerializingFirestoreData(obj: any): any {
    if (Array.isArray(obj)) {
      for (const el of obj) {
        this.prepareSerializingFirestoreData(el)
      }
    } else if (typeof obj === "object") {
      if (obj && obj["firestore"] && obj["_delegate"]) {
        obj.id2 = (obj as any).id
        obj.path2 = (obj as any).path
        obj.firestore = true
        delete obj._delegate
        delete obj._userDataWriter
        Object.defineProperty(obj, "id", {
          get: function () {
            return obj.id2
          },
        })
        Object.defineProperty(obj, "path", {
          get: function () {
            return obj.path2
          },
        })
      } else {
        for (const key in obj) this.prepareSerializingFirestoreData(obj[key])
      }
    }
  }

  getRefFromId(id: string): DocumentReference<unknown> {
    const collectionKey = this.config.collectionKey
    return this.firestore.doc(`${collectionKey}/${id}`).ref
  }

  // async existingOfflineData(id: string, happeningCollectionKey = true): Promise<boolean> {
  //   const data = await localforage.getItem((happeningCollectionKey ? this.config.collectionKey : "") + id)
  //   return !!data
  // }

  // async setOfflineData(id: string, data: any): Promise<void> {
  //   this.prepareSerializingFirestoreData(data)
  //   localforage.setItem(this.config.collectionKey + id, data)
  // }
  //
  // async getOfflineData(id: string, force = false): Promise<any> {
  //   if (navigator.onLine === false || force) {
  //     const data = await localforage.getItem(this.config.collectionKey + id)
  //     this.revivingFirestoreData(data) // redefining path and id getters
  //     if (force) {
  //       console.debug("Retrieved offline data by force:", this.config.collectionKey, id)
  //     }
  //     return data
  //   }
  // }

  revivingFirestoreData(obj: any): void {
    // redefining path and id getters
    if (Array.isArray(obj)) {
      for (const el of obj) {
        this.revivingFirestoreData(el)
      }
    } else if (typeof obj === "object") {
      for (const key in obj) {
        if (obj["firestore"] === true) {
          if (!obj.hasOwnProperty("id")) {
            Object.defineProperty(obj, "id", {
              get: function () {
                return obj.id2
              },
            })
            Object.defineProperty(obj, "path", {
              get: function () {
                return obj.path2
              },
            })
          }
        } else {
          this.revivingFirestoreData(obj[key])
        }
      }
    }
  }

  saveAsFile(data: any, name: string) {
    const link = document.createElement("a") as any
    document.body.appendChild(link)
    link.style = "display: none"
    const url = URL.createObjectURL(data.target.response)
    link.href = url
    link.download = name
    link.click()
    URL.revokeObjectURL(url)
    link.remove()
  }
}

interface Config {
  collectionKey: string
  fullTextFields: string[]
  sorting?: {
    field: string
    order?: "asc" | "desc"
  }
  fields?: Array<{
    field: string
    header: string
    type: "checkbox" | "color" | "number"
    sort: boolean
    sortField: string
  }> // only used in referentialServices
}
