import { Component, EventEmitter, inject, Input, OnInit, Output } from "@angular/core"
import { OrderListModule } from "primeng/orderlist"
import { ProgramService, SectionDTO } from "@services/program.service"
import { PickListModule } from "primeng/picklist"
import { SubSectionDTO, SubSectionService } from "@services/sub-section.service"
import { InputTextModule } from "primeng/inputtext"
import { PaginatorModule } from "primeng/paginator"
import { ReactiveFormsModule } from "@angular/forms"
import { JsonPipe } from "@angular/common"
import { ButtonModule } from "primeng/button"
import { DialogService } from "primeng/dynamicdialog"
import { AdminSubsectionComponent } from "../admin-subsection/admin-subsection.component"
import { DialogModule } from "primeng/dialog"
import { SubSectionComponent } from "../../program/sub-section/sub-section.component"
import { SectionComponent } from "../../program/section/section.component"
import { SectionPubDTO } from "@services/program.published.service"
import { NotifService } from "@services/notif.service"

@Component({
  selector: "app-admin-section",
  standalone: true,
  imports: [
    OrderListModule,
    PickListModule,
    InputTextModule,
    PaginatorModule,
    ReactiveFormsModule,
    JsonPipe,
    ButtonModule,
    DialogModule,
    SectionComponent,
    SubSectionComponent,
  ],
  templateUrl: "./admin-section.component.html",
  styleUrl: "./admin-section.component.scss",
})
export class AdminSectionComponent implements OnInit {
  notifService = inject(NotifService)
  programService = inject(ProgramService)
  subSectionService = inject(SubSectionService)
  dialogService = inject(DialogService)
  source: Array<string> = []
  expanded = false
  showPreviewPopup = false
  sectionPreview?: SectionPubDTO

  @Output() onSubsectionUpdated = new EventEmitter<string>()
  @Output() onMove = new EventEmitter<boolean>()
  @Output() onDelete = new EventEmitter()

  _subSections: Array<SubSectionDTO> = []
  @Input() set subSections(subs: Array<SubSectionDTO>) {
    this._subSections = subs
    this.source = subs.map((s) => s.id || "")
    this.init()
  }

  @Input() _section: SectionDTO | undefined
  @Input() set section(section: SectionDTO) {
    this._section = section
    this.init()
  }

  @Input() mapSubSections: {
    [id: string]: SubSectionDTO | undefined
  } = {}

  async ngOnInit() {}

  init() {
    if (this._section?.id && this._subSections?.length > 0) {
      this.source = this.source.filter((id) => !this._section?.subsectionsIds?.includes(id))
    }
  }

  openSubSectionDetails(id: string) {
    this.dialogService
      .open(AdminSubsectionComponent, {
        header: `Modification de la sous-section '${this.mapSubSections[id]?.title_internal}'`,
        width: "98vw",
        data: {
          id,
        },
      })
      .onClose.subscribe(() => this.onSubsectionUpdated.emit(id))
  }

  public toggleExpand(value: boolean) {
    this.expanded = value
  }

  async updatePreview(section: SectionDTO) {
    this.sectionPreview = await this.programService.convertSection(section)
  }

  async addSubSection() {
    if (!this._section) return
    const subSection = await this.subSectionService.create({
      title: "Nouvelle Sous-section",
      title_internal: "",
      date: new Date(),
      content: "",
    })
    if (subSection) {
      this.notifService.displaySuccess("Une nouvelle section a été ajoutée.")
      this.onSubsectionUpdated.emit(subSection.id)
    }
  }
}
