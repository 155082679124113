import { Routes } from "@angular/router"
import { SignInComponent } from "./pages/sign-in/sign-in.component"
import { HomeComponent } from "./pages/home/home.component"
import { ProgramComponent } from "./pages/program/program.component"
import { AdminHomeComponent } from "./pages/admin/admin-home/admin-home.component"
import { AdminProgramEditComponent } from "./pages/admin/admin-program-edit/admin-program-edit.component"

export const routes: Routes = [
  {
    path: "",
    component: HomeComponent,
  },
  {
    path: "admin",
    component: AdminHomeComponent,
  },
  {
    path: "admin/program",
    component: AdminProgramEditComponent,
  },
  {
    path: "admin/program/:id",
    component: AdminProgramEditComponent,
  },
  {
    path: "signIn",
    component: SignInComponent,
  },
  {
    path: "program/:id",
    component: ProgramComponent,
  },
  { path: "**", redirectTo: "", pathMatch: "prefix" },
]
