import { Component, inject, Input, OnInit, ViewChild } from "@angular/core"
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from "@angular/forms"
import { InputTextModule } from "primeng/inputtext"
import { NotifService } from "@services/notif.service"
import { Editor, EditorModule } from "primeng/editor"
import { ButtonModule } from "primeng/button"
import { SubSectionDTO, SubSectionService, SubVersionDTO } from "@services/sub-section.service"
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog"
import { FileUploadModule } from "primeng/fileupload"
import { Editor2Component } from "../../../components/editor2/editor2.component"
import { REFERENTIAL } from "@pages/admin/referential/referential.service"
import { DropdownRefFirestoreComponent } from "@components/dropdown-ref-firestore/dropdown-ref-firestore.component"
import { DropdownModule } from "primeng/dropdown"
import { JsonPipe } from "@angular/common"
import { Utils } from "@services/utils"

@Component({
  selector: "app-admin-subsection",
  standalone: true,
  imports: [
    FormsModule,
    InputTextModule,
    ReactiveFormsModule,
    EditorModule,
    ButtonModule,
    FileUploadModule,
    Editor2Component,
    DropdownRefFirestoreComponent,
    DropdownModule,
    JsonPipe,
  ],
  templateUrl: "./admin-subsection.component.html",
  styleUrl: "./admin-subsection.component.scss",
})
export class AdminSubsectionComponent implements OnInit {
  @ViewChild(Editor) editorComponent?: Editor

  public Referential = REFERENTIAL

  subSection: SubSectionDTO | undefined
  currentVersionId = ""
  currentVersionIndex = -1
  subSectionService = inject(SubSectionService)
  notifService = inject(NotifService)
  dynamicDialogRef = inject(DynamicDialogRef)
  dialogConfig = inject(DynamicDialogConfig)

  getEmptyVersion(): SubVersionDTO {
    return {
      content: "",
      date: new Date(),
      title: "-",
      id: this.subSectionService.uuid(),
    }
  }

  async ngOnInit() {
    const id = (this.dialogConfig?.data && this.dialogConfig?.data["id"]) || ""
    let subSection: SubSectionDTO | undefined = {
      title_internal: "",
      versions: [],
      category: undefined,
      title: "",
      date: new Date(),
      content: "",
      id: "",
    }
    if (id) {
      subSection = await this.subSectionService.get(id)
      subSection.versions = subSection.versions || []
    }
    if (subSection.versions.length === 0) {
      subSection.versions.push(this.getEmptyVersion())
    }
    this.subSection = subSection
    this.setCurrentVersion(0)
  }

  setCurrentVersion(index: number) {
    if (!this.subSection) return
    this.currentVersionIndex = index
    this.currentVersionId = this.subSection.versions[this.currentVersionIndex].id
  }

  // async createVersion() {
  //   if (this.subSection?.id) {
  //     const version: SubVersionDTO = {
  //       content: this.fg.get("content")?.value,
  //       title: this.fg.get("title")?.value,
  //       date: new Date(),
  //       id: "",
  //     }
  //     const result = await this.subSectionService.createVersion(this.subSection.id, version)
  //   }
  // }

  async saveVersion() {
    let result = null
    if (this.subSection?.id) {
      result = await this.subSectionService.update({
        ...this.subSection,
      })
      this.notifService.displaySuccess("La sous-section a été enregistrée.")
    } else {
      result = await this.subSectionService.create({
        id: "",
        ...this.subSection,
      })
      this.notifService.displaySuccess("La sous-section a été créée.")
    }

    this.dynamicDialogRef.close(result)
  }

  async newVersion(empty = true) {
    if (!this.subSection) return
    const newVersion = this.getEmptyVersion()
    if (!empty) {
      const currentVersion = this.subSection.versions[this.currentVersionIndex]
      newVersion.title = currentVersion.title + "_copy"
      newVersion.content = currentVersion.content
    }
    this.subSection.versions.push(newVersion)
    this.setCurrentVersion(this.subSection.versions.length - 1)
  }

  versionChange() {
    let index = this.subSection?.versions?.findIndex((x) => x.id === this.currentVersionId)
    this.currentVersionIndex = index == null ? -1 : index
  }

  async versionTitleChange() {
    //updating version dropdown
    const old = this.currentVersionId
    this.currentVersionId = ""
    await Utils.wait$(0)
    this.currentVersionId = old
  }
}
