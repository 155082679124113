@if (section) {

  @if (showTitle()) {
    <div class="mt-3 title">
      {{ section.title }}
    </div>
    <div class="separator2 mb-6"></div>
  }

  <!--  @for (sub of section.subSections; track sub.title) {-->
    <!--    <app-sub-section [subSection]="sub"></app-sub-section>-->
    <!--  }-->

  <app-sub-section [subSection]="programPubService.getCurrentSubSection()"></app-sub-section>
}
