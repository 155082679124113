@if (info) {
  <div class="text-center pt-3 pb-5" [class]="{'container': true, 'container-big': big, 'container-small': !big}">
    <div class="name">{{ info.name }}</div>
    @if (big) {
      <div class="town">{{ info.town }}</div>
      <div class="date">{{ info.date }}</div>
    } @else {
      <div class="town">{{ info.town }} {{ info.date }}</div>
    }
  </div>
}
