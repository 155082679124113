import { Component, inject, OnInit } from "@angular/core"
import { ProgramPublishedService } from "@services/program.published.service"
import { OverlayPanelModule } from "primeng/overlaypanel"
import { InfoDetailComponent } from "../info-detail/info-detail.component"
import { ConfirmationService } from "primeng/api"
import { Router } from "@angular/router"
import { AuthService } from "@services/auth.service"
import { DialogModule } from "primeng/dialog"

@Component({
  selector: "app-summary",
  standalone: true,
  imports: [OverlayPanelModule, InfoDetailComponent, DialogModule],
  templateUrl: "./summary.component.html",
  styleUrl: "./summary.component.scss",
})
export class SummaryComponent implements OnInit {
  programPubService = inject(ProgramPublishedService)
  confirmService = inject(ConfirmationService)
  router = inject(Router)
  authService = inject(AuthService)

  visible = false

  async ngOnInit() {}

  signOut() {
    this.confirmService.confirm({
      message: `Vous avez besoin de rester connecté pour accéder au programme.`,
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Me déconnecter",
      rejectLabel: "Annuler",
      accept: async () => {
        await this.authService.signOut()
        this.router.navigateByUrl("/")
      },
      reject: () => {},
    })
  }
}
