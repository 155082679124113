import { Component, Input } from "@angular/core"
import { ProgramPubDTO } from "@services/program.published.service"

@Component({
  selector: "app-info-detail",
  standalone: true,
  imports: [],
  templateUrl: "./info-detail.component.html",
  styleUrl: "./info-detail.component.scss",
})
export class InfoDetailComponent {
  @Input() big = true
  @Input() info: ProgramPubDTO | undefined
}
