<div class="mt-3">
  <form [formGroup]="form">
    <ng-container *ngFor="let field of fields">
      <div class="font-bold ml-1">{{ field.header }}</div>
      <container-element [ngSwitch]="field.type">
          <span *ngSwitchCase="'color'">
            <p-colorPicker
              [formControlName]="field.field"
              appendTo="body"
              (ngModelChange)="input.value = ''"
            ></p-colorPicker>
            <input
              #input
              pInputText
              [formControlName]="field.field"
              [style]="'color:' + form.get(field.field)?.value"
              (keydown.enter)="save()"
            />
          </span>
        <p-checkbox *ngSwitchCase="'checkbox'" [formControlName]="field.field" [binary]="true"></p-checkbox>
        <input *ngSwitchDefault pInputText [formControlName]="field.field" (keydown.enter)="save()"
               [type]="field.type || ''"/>
      </container-element>
    </ng-container>
  </form>

  <div class="text-center mt-3">
    <button
      *ngIf="!processing"
      pButton
      label="Enregistrer"
      (click)="save()"
      [style.opacity]="form.invalid ? 0.5 : 1"
    ></button>
    <p-progressSpinner
      *ngIf="processing"
      [style]="{ width: '30px', height: '30px' }"
      [strokeWidth]="'2px'"
    ></p-progressSpinner>
  </div>
</div>
