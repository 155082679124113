<p-dialog
  [(visible)]="popupMessage"
  [modal]="true"
  class="popupMessage"
  [header]="'Modification du message d\'inaccessibilité'"
  [style]="{ width: '100vw', height: '100vh'}"
>
  <div class="content flex justify-content-center">
    <div style="width: 80vw">
      <app-editor2 [(ngModel)]="disabledMessage" [storageId]="programId"
                   [style]="{ height: 'calc(100vh - 300px)', width: '100%' }"></app-editor2>
    </div>
  </div>

  <div class="ui-dialog-footer text-center mt-4 flex justify-content-around">
    <p-button (click)="saveMessage()">Enregistrer</p-button>
    <p-button (click)="cancelMessage()">Annuler</p-button>
  </div>
</p-dialog>

<p-dialog
  [(visible)]="popupUpdateMail"
  [modal]="true"
  class="updateMail"
  [header]="'Modification du mail'"
  [style]="{ width: '100vw', height: '100vh'}"
>
  <div class="content flex justify-content-center">
    <div style="width: 80vw">
      <div class="flex flex-column gap-2 mt-6 mb-3">
        <label for="email_label" class="white">Sujet du mail</label>
        <input type="email" [(ngModel)]="email.subject"
               pInputText id="email_label" required/>
      </div>

      <div class="mt-3">
        <div class="mb-2">
          <span class="text-red">Templating:</span>
          <span class="bg-gray-500 ml-3">Le lien est ##ici##</span> deviendra dans le mail =>
          <span class="bg-gray-500 ml-3">Le lien est <span class="text-blue-800 underline">ici</span></span>
        </div>
        <app-editor2 [(ngModel)]="email.content" [storageId]="programId"
                     [style]="{ height: 'calc(100vh - 472px)', 'width': '100%' }"></app-editor2>
      </div>
    </div>
  </div>

  <div class="ui-dialog-footer text-center mt-4 flex justify-content-around">
    <p-button (click)="saveMail()">Enregistrer</p-button>
    <p-button (click)="cancelMail()">Annuler</p-button>
  </div>
</p-dialog>

<p-dialog
  [(visible)]="popupPreview"
  [modal]="true"
  class="preview"
  [header]="'Aperçu du programme \'' + currentProgram?.name + '\''"
  [style]="{ width: '100vw', height: '100vh'}"
>
  <div class="content flex justify-content-center">
    <div style="max-width: 500px">

      @for (section of sectionsPreview; track section.id) {
        <div class="section">
          <h1 class="title mb-3" style="color:blueviolet">{{ section?.title }}</h1>
          @for (subSection of section?.subSections; track subSection.id) {
            <app-sub-section [subSection]="subSection"></app-sub-section>
            <div>________________</div>
          }
          <div class="separator2"></div>
        </div>
      }
    </div>
  </div>

  <div class="ui-dialog-footer text-center mt-4">
    <p-button (click)="popupPreview = false">Fermer la section</p-button>
  </div>
</p-dialog>

<div class="g_title mt-3 mb-3 text-center relative">
  <span>Programme <small class="font-italic">({{ currentProgram?.name || 'Création...' }})</small></span>
  <i [routerLink]="'/admin'"
     class="cursor-pointer absolute pi pi-arrow-left ml-auto" style="left:0;top:10px"></i>
</div>

<div class="container">
  <p-tabView [(activeIndex)]="activeIndex" (activeIndexChange)="changeActive()">
    <!--    GENERAL-->
    <p-tabPanel header="Général">
      <div class="scroller_container">
        <form [formGroup]="fg">
          @if (currentProgram?.accessible) {
            <div style="color: limegreen" class="mb-2 mt-2">La programme est actuellement accessible à tous.</div>
            <div class="flex align-items-center">
              <p-button (click)="changeAccessibleAndPublish(false)">
                <b><span style="color: red">Rendre inaccessible</span>&nbsp;et publier</b>
              </p-button>
              <div class="ml-4 cursor-pointer" (click)="popupMessage = true">
                <i class="pi pi-pencil text-xs mr-2"></i>Modifier le message d'inaccessibilité
                <span class="text-gray-400 mr-2">(il faudra ensuite publier les modifs)</span>
              </div>
            </div>
          } @else {
            <div style="color: red" class="mb-2">Le programme est actuellement inaccessible.</div>
            <div class="flex align-items-center">
              <p-button (click)="changeAccessibleAndPublish(true)">
                <b><span style="color: darkgreen">Rendre accessible</span>&nbsp; et publier</b>
              </p-button>
              <div class="ml-4 cursor-pointer" (click)="popupMessage = true">
                <i class="pi pi-pencil text-xs mr-2"></i>Modifier le message d'inaccessibilité
                <span class="text-gray-400 mr-2">(il faudra ensuite publier les modifs)</span>
              </div>
            </div>
          }

          <div class="flex flex-column gap-2 mb-3 mt-3">
            <label for="name">Nom *</label>
            <input formControlName="name" required
                   pInputText id="name"/>
          </div>

          <div class="flex flex-column gap-2 mb-3">
            <label for="date">Date *</label>
            <input formControlName="date"
                   pInputText id="date"/>
          </div>

          <div class="flex flex-column gap-2  mb-4">
            <label for="town">Ville *</label>
            <input formControlName="town"
                   pInputText id="town"/>
          </div>

          <div class="mb-4 flex align-items-center">
            <p-button label="Modifier le mail" (click)="popupUpdateMail = true"></p-button>
            <span class="text-xs ml-4">(pas besoin de publier le programme pour que cela soit pris en compte)</span>
          </div>
        </form>
        <div class="g_title flex align-items-center mb-3">INTRO
          <i title="Modifier l'introduction" class="pi pi-pencil ml-auto cursor-pointer"
             (click)="openSubSectionDetails(introductionId || '')"></i></div>
        <p-dropdown [options]="allSubsections" [(ngModel)]="introductionId"
                    optionValue="id" optionLabel="title" [showClear]="true" styleClass="w-full"
                    placeholder="Selectionner une sous-section"></p-dropdown>
      </div>
    </p-tabPanel>

    @if (false) {
      <!--    SECTIONS-->
      <p-tabPanel header="Sections">
        <div class="scroller_container">
          <div class="mb-3">
            <p-button icon="pi pi-plus" label="Ajouter une section" (click)="addSection()"></p-button>
          </div>
          @for (section of currentProgram?.sections; track section.id) {
            <app-admin-section2
              [section]="section"
              [subSections]="allSubsections"
              [mapSubSections]="mapSubSections"
            ></app-admin-section2>
          }
        </div>
      </p-tabPanel>
      <!--    SECTIONS-OLD-->
      <p-tabPanel header="Sections-old">
        <div class="scroller_container">
          <div class=" g_title mt-2 flex align-items-center mb-3 cursor-pointer">
            SECTIONS
            <i title="Ajouter une section" class="pi pi-plus ml-4" (click)="addSection()"></i>
            <div class="ml-auto">
              <i title="Ajouter une section" class="pi pi-eye mr-6" (click)="updatePreview();popupPreview = true"></i>
              <i title="Tout étandre" class="pi pi-angle-double-down mr-4" (click)="expandAll(true)"></i>
              <i title="Tout réduire" class="pi pi-angle-double-up" (click)="expandAll(false)"></i></div>
          </div>
          @for (section of currentProgram?.sections; track section.id) {
            <app-admin-section
              #section
              [style]="'opacity:'+ (section.removing ? 0 : 1)"
              [subSections]="allSubsections"
              [mapSubSections]="mapSubSections"
              (onSubsectionUpdated)="updateMapSubsections($event)"
              (onMove)="moveSection($event, section.id || '')"
              (onDelete)="deleteSection(section.id || '')"
              [section]="section"></app-admin-section>
          }
        </div>
      </p-tabPanel>
    }
    <p-tabPanel header="Sections">
      <div class="scroller_container">
        WIP
      </div>
    </p-tabPanel>

    <!--    SUB-SECTIONS-->
    <p-tabPanel header="Sous-sections">
      <div class="scroller_container">
        <app-admin-list-subsections></app-admin-list-subsections>
      </div>
    </p-tabPanel>

    <p-tabPanel header="Réferentiel" class="tabPanel-referentiel">
      <div class="scroller_container">
        <app-referential-list></app-referential-list>
      </div>
    </p-tabPanel>

    <!--    IMAGES-->
    <!--    <p-tabPanel header="Gestion des images">-->
    <!--      <div class="scroller_container">-->
    <!--        <p-fileUpload-->
    <!--          mode="basic"-->
    <!--          [auto]="true"-->
    <!--          accept=".mp4,image/*"-->
    <!--          [maxFileSize]="1000000"-->
    <!--          [customUpload]="true"-->
    <!--          (uploadHandler)="onUpload($event)"-->
    <!--          chooseLabel="Uploader une image"-->
    <!--          class="primary"-->
    <!--        />-->

    <!--        <h3>Images du programmes:</h3>-->
    <!--        <div class="flex align-items-center">-->
    <!--          @for (image of images; track image.name) {-->
    <!--            <div class="mr-2">-->
    <!--              <img style="width: 100px; height: auto" [src]="image.src"/>-->
    <!--              <div class="font-italic">{{ image.name }}</div>-->
    <!--            </div>-->
    <!--          }-->
    <!--        </div>-->
    <!--      </div>-->

    <!--    </p-tabPanel>-->
  </p-tabView>


  <div class="text-center mt-6 flex justify-content-around">
    <p-button [label]="(currentProgram?.id ? 'Enregistrer ' : 'Créer ') + ' le programme'" (click)="save()"></p-button>
    @if (currentProgram?.id) {
      <p-button label="Aperçu" (click)="window.alert('TODO')"></p-button>
      <p-button label="Enregistrer et publier" (click)="publish()"></p-button>
    }
  </div>
</div>
