<div class="pt-4 pb-4 fixed z-2 header-fixed" style="background-color: black;">
  <div class="flex align-items-center">
    @if (programPubService.currentProgram?.accessible) {
      <app-summary></app-summary>
    }
    <div class="header" [class]="{'ml-auto': programPubService.currentProgram?.accessible,
    'm-auto': !programPubService.currentProgram?.accessible}"></div>
  </div>
</div>


@if (programPubService.currentProgram?.accessible) {
  <div class="vertical-header">
    <div class="text">{{ programPubService.getCurrentSection()?.title }}</div>
  </div>
}
<div style="height: 100px"></div>

@if (!programPubService.currentProgram?.accessible) {
  <div style="height: 20px"></div>
  <app-info-detail [big]="false" [info]="programPubService.currentProgram"></app-info-detail>
}

<div class="container mt-2 min-width">
  <!--  <app-info-detail [big]="false" [info]="programPubService.currentProgram"></app-info-detail>-->
  @if (programPubService.currentProgram && !programPubService.currentProgram.accessible) {
    <div class="text-center mt-6 inner-html-quill" [innerHTML]="programPubService.currentProgram.disabledMessage ||
'<h2>Programme actuellement indisponible</h2>'">
    </div>
  } @else if (programPubService.currentProgram && programPubService.currentProgram.accessible) {
    <app-section [section]="programPubService.getCurrentSection()"></app-section>
    @if (programPubService.currentProgram.sections.length === 0) {
      <div class="w-full text-center mt-6">
        <p-button label="Merci de vous connecter" routerLink="/"></p-button>
      </div>
    } @else {

      <div class="flex justify-content-between uppercase mt-6 arrows mr-1">
        <div class="font-bold cursor-pointer" style="width: 50%"
             [style.visibility]="programPubService.currentSubSectionIndex > 0 ? 'visible': 'hidden'"
             (click)="prev()">
          <div class="mb-2 text-left"><i class="pi pi-arrow-left"></i></div>
          <div class="mb-2 font-next">Section précèdente</div>
          @if (programPubService.currentSubSectionIndex > 0) {
            <div class="font-section-name"
            >{{ programPubService.currentSubSections[programPubService.currentSubSectionIndex - 1].title }}
            </div>
          }
        </div>
        <div class="font-bold cursor-pointer ml-1 text-right" style="width: 50%"
             [style.visibility]="programPubService.currentSubSectionIndex >= programPubService.currentSubSections.length - 1  ? 'hidden': 'visible'"
             (click)="next()">
          <div class="mb-2 "><i class="pi pi-arrow-right"></i></div>
          <div class="mb-2 font-next">Section suivante</div>
          @if (programPubService.currentSubSectionIndex < programPubService.currentSubSections.length - 1) {
            <div
              class="font-section-name">{{ programPubService.currentSubSections[programPubService.currentSubSectionIndex + 1].title }}
            </div>
          }
        </div>
      </div>
    }
  }
</div>
