import { Component, inject, OnInit } from "@angular/core"
import { ProgramDTO, ProgramService } from "@services/program.service"
import { ButtonModule } from "primeng/button"
import { RouterLink } from "@angular/router"
import { ProgramPubDTO, ProgramPublishedService } from "@services/program.published.service"

@Component({
  selector: "app-admin-home",
  standalone: true,
  imports: [ButtonModule, RouterLink],
  templateUrl: "./admin-home.component.html",
  styleUrl: "./admin-home.component.scss",
})
export class AdminHomeComponent implements OnInit {
  showCreate = false
  programService = inject(ProgramService)
  programPubService = inject(ProgramPublishedService)
  programs: Array<ProgramDTO> = []
  currentProgram?: ProgramPubDTO

  async ngOnInit() {
    this.programs = await this.programService.getAllFromCache()
    this.currentProgram = await this.programPubService.getCurrentProgramNotConnected()
  }

  debug(event: any) {
    if (event.ctrlKey) {
      this.showCreate = true
    }
  }
}
