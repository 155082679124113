import { Component, forwardRef, inject, Input, ViewChild } from "@angular/core"
import { Editor, EditorModule } from "primeng/editor"
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms"
import { SharedModule } from "primeng/api"
import { StorageService } from "@services/storage.service"

@Component({
  selector: "app-editor2",
  standalone: true,
  imports: [EditorModule, ReactiveFormsModule, SharedModule, FormsModule],
  templateUrl: "./editor2.component.html",
  styleUrl: "./editor2.component.scss",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Editor2Component),
      multi: true,
    },
  ],
})
export class Editor2Component implements ControlValueAccessor {
  storageService = inject(StorageService)

  @ViewChild(Editor) editorComponent?: Editor
  @Input() storageId = "XXXXX"
  @Input() formControlName = ""
  @Input() style: any = { height: "320px" }
  model = ""

  quillModules = {
    clipboard: {
      matchVisual: false, //FIX for https://github.com/quilljs/quill/issues/2905
    },
    toolbar: {
      container: ".p-editor-toolbar",
      handlers: {
        image: () => {
          const _this = this
          const quill = this.editorComponent?.quill
          if (!quill) {
            console.error("Quill should not be null!!")
            return
          }
          const container = quill.container?.parentElement
          let fileInput = container.querySelector("input.ql-image[type=file]")
          if (fileInput == null) {
            fileInput = document.createElement("input")
            fileInput.setAttribute("type", "file")
            fileInput.setAttribute("accept", "image/png, image/gif, image/jpeg, image/bmp, image/x-icon")
            fileInput.classList.add("ql-image")
            fileInput.addEventListener("change", () => {
              if (fileInput.files != null && fileInput.files[0] != null) {
                var reader = new FileReader()
                reader.onload = async (e) => {
                  var range = quill.getSelection(true)
                  // quill.updateContents(
                  //   new Delta().retain(range.index).delete(range.length).insert({ image: e.target?.result }),
                  //   "user"
                  // )
                  quill.setSelection(range.index + 1, "silent")
                  const result = (await _this.storageService.uploadFile(fileInput.files[0], this.storageId)) as any
                  fileInput.value = ""
                  if (result?.src) {
                    quill.insertEmbed(range.index, "image", result.src, "user" /*otherwise ngModel not updated*/)
                  }
                }
                reader.readAsDataURL(fileInput.files[0])
              }
            })
            container.appendChild(fileInput)
          }
          fileInput.click()
          fileInput.remove()
        },
      },
    },
  }

  onChange!: (value: string) => void
  onTouched!: () => void

  writeValue(value: string): void {
    this.model = value
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }
}
