import { Component, inject, OnInit } from "@angular/core"
import { ProgramPublishedService } from "@services/program.published.service"
import { SubSectionComponent } from "./sub-section/sub-section.component"
import { SectionComponent } from "./section/section.component"
import { MenubarModule } from "primeng/menubar"
import { InfoDetailComponent } from "./info-detail/info-detail.component"
import { ActivatedRoute, Router } from "@angular/router"
import { NotifService } from "@services/notif.service"
import { ButtonModule } from "primeng/button"
import { JsonPipe } from "@angular/common"
import { SummaryComponent } from "./summary/summary.component"

@Component({
  selector: "app-program",
  standalone: true,
  imports: [
    SubSectionComponent,
    SectionComponent,
    MenubarModule,
    InfoDetailComponent,
    ButtonModule,
    JsonPipe,
    SummaryComponent,
  ],
  templateUrl: "./program.component.html",
  styleUrl: "./program.component.scss",
})
export class ProgramComponent implements OnInit {
  programPubService = inject(ProgramPublishedService)
  route = inject(ActivatedRoute)
  router = inject(Router)
  notifService = inject(NotifService)

  async ngOnInit() {
    const programId = this.route.snapshot.params["id"] || ""
    const subId = this.route.snapshot.queryParams["id"]
    await this.programPubService.initProgramWithSection(programId, subId)
    if (this.programPubService.currentProgram) {
    } else {
      this.notifService.displayError("Impossible d'afficher le programme demandé")
      this.router.navigateByUrl(`/`)
    }
  }

  scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
    })
  }

  async next() {
    this.programPubService.next()
    this.scrollTop()
  }

  async prev() {
    this.programPubService.prev()
    this.scrollTop()
  }
}
