<div class="content">
  <app-list
    #list
    storageKey="subsections"
    [tableData]="dataItems"
    [loading]="loading"
    (fetchData)="fetchData($event)"
    (actionClick)="actionClick($event)"
    (fetchMoreData)="fetchMore()"
    [infiniteScroll]="true"
    [scrollHeight]="listHeight"
  >
    <p-button styleClass="p-button ml-2" (click)="createSubsection()">Créer une sous section</p-button>
  </app-list>
</div>
