<div class="mt-3 ml-2">
  <p-selectButton
    [options]="refOptions"
    optionLabel="name"
    [(ngModel)]="selectedReferential"
    optionValue="value"
    (onChange)="changeReferential()"
  ></p-selectButton>
</div>
<div class="content">
  <app-list
    storageKey="refs"
    [tableData]="dataItems"
    [loading]="loading"
    (fetchData)="fetchData($event)"
    (actionClick)="actionClick($event)"
    (fetchMoreData)="fetchMore()"
    #list
    [infiniteScroll]="true"
    [scrollHeight]="listHeight"
  >
    <p-button styleClass="p-button ml-2" (click)="createReferential()">
      Créer "{{ referentialService.getReferentialLabel(selectedReferential) }}"
    </p-button>
  </app-list>
</div>
