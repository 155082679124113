import { Component, inject, OnInit, QueryList, ViewChildren } from "@angular/core"
import { InputTextModule } from "primeng/inputtext"
import { FormsModule, ReactiveFormsModule, UntypedFormBuilder, Validators } from "@angular/forms"
import { JsonPipe } from "@angular/common"
import { ButtonModule } from "primeng/button"
import { ProgramDTO, ProgramService, SectionDTO } from "@services/program.service"
import { NotifService } from "@services/notif.service"
import { ActivatedRoute, RouterLink } from "@angular/router"
import { AdminSubsectionComponent } from "../admin-subsection/admin-subsection.component"
import { AdminSectionComponent } from "../admin-section/admin-section.component"
import { SubSectionDTO, SubSectionService } from "@services/sub-section.service"
import { DropdownModule } from "primeng/dropdown"
import { ConfirmationService } from "primeng/api"
import { Utils } from "@services/utils"
import { DialogModule } from "primeng/dialog"
import { SubSectionComponent } from "../../program/sub-section/sub-section.component"
import { SectionPubDTO } from "@services/program.published.service"
import { DialogService } from "primeng/dynamicdialog"
import { RadioButtonModule } from "primeng/radiobutton"
import { EditorModule } from "primeng/editor"
import { TabViewModule } from "primeng/tabview"
import { AdminListSubsectionsComponent } from "./admin-list-subsections/admin-list-subsections.component"
import { FileUploadModule } from "primeng/fileupload"
import { ImagesStorage, StorageService } from "@services/storage.service"
import { Editor2Component } from "../../../components/editor2/editor2.component"
import { ReferentialListComponent } from "../referential/referential-list/referential-list.component"
import { AdminSectionComponent2 } from "@pages/admin/admin-section2/admin-section.component"

@Component({
  selector: "app-admin-program-edit",
  standalone: true,
  imports: [
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    JsonPipe,
    ButtonModule,
    RouterLink,
    AdminSubsectionComponent,
    AdminSectionComponent,
    DropdownModule,
    DialogModule,
    SubSectionComponent,
    RadioButtonModule,
    EditorModule,
    TabViewModule,
    AdminListSubsectionsComponent,
    FileUploadModule,
    Editor2Component,
    ReferentialListComponent,
    AdminSectionComponent2,
  ],
  templateUrl: "./admin-program-edit.component.html",
  styleUrl: "./admin-program-edit.component.scss",
})
export class AdminProgramEditComponent implements OnInit {
  @ViewChildren("section") sectionComponents?: QueryList<AdminSectionComponent>
  programService = inject(ProgramService)
  confirmService = inject(ConfirmationService)
  fb = inject(UntypedFormBuilder)
  notifService = inject(NotifService)
  route = inject(ActivatedRoute)
  subSectionService = inject(SubSectionService)
  dialogService = inject(DialogService)
  storageService = inject(StorageService)
  activeIndex = localStorage["admin-program-edit_activeIndex"] || 0

  introductionId? = ""
  currentProgram?: ProgramDTO
  allSubsections: Array<SubSectionDTO> = []
  sectionsPreview: Array<SectionPubDTO> = []
  mapSubSections: {
    [id: string]: SubSectionDTO | undefined
  } = {}
  programId = ""

  popupPreview = false
  popupUpdateMail = false
  popupMessage = false
  email = {
    content: "",
    subject: "",
  }
  disabledMessage = ""

  fg = this.fb.group({
    name: ["", [Validators.required]],
    town: ["", [Validators.required]],
    date: ["", [Validators.required]],
    accessible: [true, null],
  })

  images: Array<ImagesStorage> = []

  async ngOnInit() {
    this.programId = this.route.snapshot.params["id"] || ""
    this.currentProgram = await this.programService.get(this.programId)

    if (this.currentProgram) {
      this.fg.patchValue(this.currentProgram)
      this.introductionId = this.currentProgram.introductionId
      this.email = {
        content: this.currentProgram.email?.content || "",
        subject: this.currentProgram.email?.subject || "",
      }
      this.disabledMessage = this.currentProgram?.disabledMessage || ""
    }
    this.allSubsections = await this.subSectionService.getAllFromCache()

    this.mapSubSections = {}
    this.allSubsections.forEach((sub) => {
      this.mapSubSections[sub.id || ""] = sub
    })

    this.images = await this.storageService.getImages(this.programId)

    //this.subSectionService.migrateSubSection()
  }

  async updatePreview() {
    //@ts-ignore
    this.sectionsPreview = await Promise.all(
      //@ts-ignore
      this.currentProgram?.sections.map(async (section) => await this.programService.convertSection(section))
    )
    return this.sectionsPreview
  }

  async updateMapSubsections(subId: string) {
    const sub = await this.subSectionService.get(subId)
    this.mapSubSections[subId] = sub
    let subsection = this.allSubsections.find((s) => s.id === subId)
    if (subsection) {
      Object.assign(subsection, { ...sub })
    } else {
      subsection = sub
      this.allSubsections = [sub, ...this.allSubsections]
    }
    if (subsection.id === this.introductionId) {
      this.introductionId = ""
      setTimeout(() => (this.introductionId = subsection.id), 0) // dropdown not updated otherwise :(
    }
  }

  addSection() {
    if (!this.currentProgram) return
    this.currentProgram.sections = this.currentProgram.sections || []
    this.currentProgram?.sections.unshift({
      id: "RANDOM" + Math.random(),
      title: "NEW",
      subsectionsIds: [],
      subsectionsRefs: [],
      title_internal: "",
    })
  }

  deleteSection(sectionId: string) {
    const section: SectionDTO | undefined = this.currentProgram?.sections?.find((s) => (s.id || "") === sectionId)
    if (!section) return

    this.confirmService.confirm({
      message: `Êtes vous certain de vouloir supprimer la section <b>'${section?.title}'</b> ?<br> (les sous-sections ne seront pas supprimées)`,
      header: "Suppression - confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "Supprimer",
      rejectLabel: "Annuler",
      accept: async () => {
        section.removing = true
        await Utils.wait$(1000) // animation
        this.currentProgram?.sections.removeFirst((s) => s.id === sectionId)
      },
      reject: () => {},
    })
  }

  moveSection(up: boolean, sectionId: string) {
    const sections = this.currentProgram?.sections
    if (sections && sections.length > 0 && sectionId) {
      const index = sections.findIndex((s) => s.id === sectionId)
      const newIndex = index + (up ? -1 : 1)
      if (newIndex < 0 || newIndex === sections.length || index < 0) return

      const swappingSection = sections[index]
      sections[index] = sections[newIndex]
      sections[newIndex] = swappingSection
    }
  }

  openSubSectionDetails(id: string) {
    this.dialogService
      .open(AdminSubsectionComponent, {
        header: `Modification de la sous-section '${this.mapSubSections[id]?.title_internal}'`,
        width: "98vw",
        data: {
          id,
        },
      })
      .onClose.subscribe(() => this.updateMapSubsections(id))
  }

  async save(displayMessages = true) {
    const params: ProgramDTO = {
      ...this.fg.value,
      sections: this.currentProgram?.sections,
      introductionId: this.introductionId,
    }
    if (this.programId) {
      await this.programService.update({
        id: this.programId,
        ...params,
      })
      if (displayMessages) this.notifService.displaySuccess("Le programme a été enregistré.")
    } else {
      const prog = await this.programService.create(params)
      if (!prog.id) {
        this.notifService.displayError("Impossible de créer le programme.")
        return
      }
      if (displayMessages) this.notifService.displaySuccess("Le programme a été créé.")
      this.currentProgram = await this.programService.get(prog.id)
    }
  }

  cancelMessage() {
    this.disabledMessage = this.currentProgram?.disabledMessage || ""
    this.popupMessage = false
  }

  async saveMessage() {
    if (!this.currentProgram) return
    await this.programService.update({
      id: this.programId,
      disabledMessage: this.disabledMessage,
    })
    this.currentProgram.disabledMessage = this.disabledMessage
    this.notifService.displaySuccess("Le message d'inaccessibilité a été mis à jour!")
    this.popupMessage = false
  }

  cancelMail() {
    this.email = {
      content: this.currentProgram?.email?.content || "",
      subject: this.currentProgram?.email?.subject || "",
    }
    this.popupUpdateMail = false
  }

  async saveMail() {
    if (!this.currentProgram) return
    await this.programService.update({
      id: this.programId,
      email: this.email,
    })
    this.notifService.displaySuccess("Le mail a été mis à jour.")
    this.currentProgram.email = this.email
    this.popupUpdateMail = false
  }

  async publish() {
    await this.save(false)
    await this.programService.publish(this.programId)
    this.notifService.displaySuccess("Le programme a été publié.")
  }

  expandAll(value: boolean) {
    this.sectionComponents?.forEach((s) => s.toggleExpand(value))
  }

  async changeAccessibleAndPublish(accessible: boolean) {
    if (!this.currentProgram) return
    this.currentProgram.accessible = accessible
    this.fg.patchValue({ accessible }) // important !!
    await this.programService.update({
      id: this.programId,
      accessible,
    })
    await this.programService.publish(this.programId)
    const label = accessible ? "activé" : "désactivé"
    this.notifService.displaySuccess(`Le programme a été ${label} puis publié`)
  }

  async onUpload(data: any) {
    if (data.files?.length > 0) {
      const file = data.files[0]
      try {
        const image = (await this.storageService.uploadFile(file, this.programId)) as ImagesStorage
        if (image?.src) {
          this.images.push(image)
        }
      } catch (err: any) {
        this.notifService.displayError(err.message)
        console.error(err)
      }
    }
  }

  changeActive() {
    localStorage["admin-program-edit_activeIndex"] = this.activeIndex
  }

  protected readonly window = window
}
