import * as i0 from '@angular/core';
import { InjectionToken, Optional, NgModule, NgZone, Injector } from '@angular/core';
import { ɵgetAllInstancesOf, ɵgetDefaultInstanceOf, VERSION, ɵAngularFireSchedulers, ɵAppCheckInstances, ɵzoneWrap } from '@angular/fire';
import { FirebaseApp, FirebaseApps } from '@angular/fire/app';
import { AuthInstances } from '@angular/fire/auth';
import { registerVersion } from 'firebase/app';
import { from, timer } from 'rxjs';
import { concatMap, distinct } from 'rxjs/operators';
import { httpsCallable as httpsCallable$1 } from 'rxfire/functions';
import { connectFunctionsEmulator as connectFunctionsEmulator$1, getFunctions as getFunctions$1, httpsCallable as httpsCallable$2, httpsCallableFromURL as httpsCallableFromURL$1 } from 'firebase/functions';
export * from 'firebase/functions';
class Functions {
  constructor(functions) {
    return functions;
  }
}
const FUNCTIONS_PROVIDER_NAME = 'functions';
class FunctionsInstances {
  constructor() {
    return ɵgetAllInstancesOf(FUNCTIONS_PROVIDER_NAME);
  }
}
const functionInstance$ = timer(0, 300).pipe(concatMap(() => from(ɵgetAllInstancesOf(FUNCTIONS_PROVIDER_NAME))), distinct());
const PROVIDED_FUNCTIONS_INSTANCES = new InjectionToken('angularfire2.functions-instances');
function defaultFunctionsInstanceFactory(provided, defaultApp) {
  const defaultAuth = ɵgetDefaultInstanceOf(FUNCTIONS_PROVIDER_NAME, provided, defaultApp);
  return defaultAuth && new Functions(defaultAuth);
}
function functionsInstanceFactory(fn) {
  return (zone, injector) => {
    const functions = zone.runOutsideAngular(() => fn(injector));
    return new Functions(functions);
  };
}
const FUNCTIONS_INSTANCES_PROVIDER = {
  provide: FunctionsInstances,
  deps: [[new Optional(), PROVIDED_FUNCTIONS_INSTANCES]]
};
const DEFAULT_FUNCTIONS_INSTANCE_PROVIDER = {
  provide: Functions,
  useFactory: defaultFunctionsInstanceFactory,
  deps: [[new Optional(), PROVIDED_FUNCTIONS_INSTANCES], FirebaseApp]
};
class FunctionsModule {
  constructor() {
    registerVersion('angularfire', VERSION.full, 'fn');
  }
  static ɵfac = function FunctionsModule_Factory(t) {
    return new (t || FunctionsModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: FunctionsModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [DEFAULT_FUNCTIONS_INSTANCE_PROVIDER, FUNCTIONS_INSTANCES_PROVIDER]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FunctionsModule, [{
    type: NgModule,
    args: [{
      providers: [DEFAULT_FUNCTIONS_INSTANCE_PROVIDER, FUNCTIONS_INSTANCES_PROVIDER]
    }]
  }], () => [], null);
})();
function provideFunctions(fn, ...deps) {
  return {
    ngModule: FunctionsModule,
    providers: [{
      provide: PROVIDED_FUNCTIONS_INSTANCES,
      useFactory: functionsInstanceFactory(fn),
      multi: true,
      deps: [NgZone, Injector, ɵAngularFireSchedulers, FirebaseApps,
      // Defensively load Auth first, if provided
      [new Optional(), AuthInstances], [new Optional(), ɵAppCheckInstances], ...deps]
    }]
  };
}

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const httpsCallableData = ɵzoneWrap(httpsCallable$1, true);

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
const connectFunctionsEmulator = ɵzoneWrap(connectFunctionsEmulator$1, true);
const getFunctions = ɵzoneWrap(getFunctions$1, true);
const httpsCallable = ɵzoneWrap(httpsCallable$2, true);
const httpsCallableFromURL = ɵzoneWrap(httpsCallableFromURL$1, true);

/**
 * Generated bundle index. Do not edit.
 */

export { Functions, FunctionsInstances, FunctionsModule, connectFunctionsEmulator, functionInstance$, getFunctions, httpsCallable, httpsCallableData, httpsCallableFromURL, provideFunctions };
