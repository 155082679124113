import { Component, OnInit, ViewChild } from "@angular/core"

import { ActionClickEvent, ListQuery, TableData, TableHeader } from "@components/list/list.model"
import { DialogService } from "primeng/dynamicdialog"
import { REFERENTIAL, ReferentialDTO, ReferentialService } from "../referential.service"
import { ListComponent } from "@components/list/list.component"
import { ReferentialEditComponent } from "../referential-edit/referential-edit.component"
import { ButtonModule } from "primeng/button"
import { SelectButtonModule } from "primeng/selectbutton"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"

const NB_ITEMS_PER_PAGE = 30

@Component({
  selector: "app-referential-list",
  templateUrl: "./referential-list.component.html",
  styleUrls: ["./referential-list.component.scss"],
  standalone: true,
  imports: [ReferentialEditComponent, ButtonModule, SelectButtonModule, ListComponent, FormsModule],
})
export class ReferentialListComponent implements OnInit {
  @ViewChild("list", { static: false }) listComponent?: ListComponent<ReferentialDTO>

  refOptions = this.referentialService.getReferentials()
  selectedReferential = REFERENTIAL.category_subsection

  loading = true
  columns: TableHeader[] = []
  dataItems: TableData<ReferentialDTO> = {
    headers: this.columns,
    values: [],
    actions: [{ id: ReferentialAction.EDIT, icon: "pi-pencil" }],
    totalRecords: 0,
    pageNumber: 0,
  }
  listHeight = `${window.innerHeight - 420}px`

  constructor(public referentialService: ReferentialService, private dialogService: DialogService) {}

  ngOnInit(): void {
    this.selectedReferential = localStorage["referential-list-selectedReferential"] || REFERENTIAL.category_subsection
    this.changeReferential()
  }

  changeReferential(): void {
    this.referentialService.changeService(this.selectedReferential)
    localStorage["referential-list-selectedReferential"] = this.selectedReferential
    if (this.listComponent) {
      this.listComponent.inputValue = ""
    }
    this.dataItems.values = []
    this.columns = this.referentialService.getColumns()
    this.dataItems.headers = this.columns
    this.fetchData({})
  }

  fetchMore(): void {
    this.referentialService.fireStoreService.getMore(NB_ITEMS_PER_PAGE).then((data) => {
      this.dataItems.values = this.dataItems.values.concat(
        // The first item will be added twice otherwise
        data.map((x) => this.itemToView(x)).filter((x) => !this.dataItems.values.find((y) => y.id === x.id))
      )
    })
  }

  fetchData(params: ListQuery): void {
    this.loading = true
    if (params.sorts || params.search) {
      this.referentialService.fireStoreService.filter(params.search, params.sorts && params.sorts[0]).then((items) => {
        this.dataItems.values = items.map((x) => this.itemToView(x))
        this.loading = false
      })
    } else {
      this.referentialService.fireStoreService.getAll(NB_ITEMS_PER_PAGE).then((items) => {
        this.dataItems.values = items.map((x) => this.itemToView(x))
        this.loading = false
      })
    }
  }

  itemToView(item: ReferentialDTO): ReferentialDTO {
    const item2 = item as any
    for (let key in item2) {
      const value = item2[key]
      if (typeof value === "boolean") {
        item2[key] = value ? "OUI" : ""
      }
    }
    return item2
  }

  actionClick(event: ActionClickEvent<ReferentialDTO>): void {
    switch (event.actionId) {
      case ReferentialAction.EDIT:
        this.updateReferential(event.item)
        break
    }
  }

  createReferential(): void {
    this.dialogService
      .open(ReferentialEditComponent, {
        header: "Créer " + this.referentialService.getReferentialLabel(this.selectedReferential),
        width: "600px",
        data: { fields: this.columns },
      })
      .onClose.subscribe((ref) => {
        if (ref) {
          this.dataItems.values.unshift(this.itemToView(ref))
          if (this.listComponent) {
            this.listComponent.selectedRow = ref.id
          }
        }
      })
  }

  updateReferential(ref: ReferentialDTO): void {
    this.dialogService
      .open(ReferentialEditComponent, {
        header: "Modifier " + this.referentialService.getReferentialLabel(this.selectedReferential),
        width: "600px",
        data: {
          referential: ref,
          fields: this.columns,
        },
      })
      .onClose.subscribe((ref2) => {
        if (ref2) {
          const toUpdate = this.dataItems.values.find((x) => x.id === ref2.id)
          if (toUpdate) {
            Object.assign(toUpdate, this.itemToView(ref2))
          }
          if (this.listComponent) {
            this.listComponent.selectedRow = ref2.id
          }
        }
      })
  }
}

enum ReferentialAction {
  EDIT = "EDIT",
  DELETE = "DELETE",
}
