const firebaseConfig = {
  apiKey: "AIzaSyCHOJ_CJIZYgXV1nMO4TJ4RD-wamy1FbaQ",
  authDomain: "backstage-dev-83e17.firebaseapp.com",
  projectId: "backstage-dev-83e17",
  storageBucket: "backstage-dev-83e17.appspot.com",
  messagingSenderId: "560335005423",
  appId: "1:560335005423:web:bedb19a2a7b09a7d49dd1a",
  measurementId: "G-63JYF9XPMB",
}

export const environment = {
  production: false,
  title: "3e etage backstage",
  firebaseConfig,
  ga: {
    trackingId: "G-JBBFRZWNTH",
  },
}
