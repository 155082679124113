import { Injectable } from "@angular/core"
import { MessageService } from "primeng/api"

enum Severity {
  Error = "error",
  Success = "success",
  Info = "info",
  Warning = "warn",
}

@Injectable({
  providedIn: "root",
})
export class NotifService {
  constructor(private messageService: MessageService) {}

  displayError(message: string, position = ""): void {
    this.displayMessage(Severity.Error, message, position)
  }

  displayInfo(message: string, position = ""): void {
    this.displayMessage(Severity.Info, message, position)
  }

  displaySuccess(message: string, position = ""): void {
    this.displayMessage(Severity.Success, message, position)
  }

  displayWarning(message: string): void {
    this.displayMessage(Severity.Warning, message, "")
  }

  private displayMessage(severity: Severity, message: string, position = "", duration = 4000): void {
    this.messageService.add({ severity, detail: message, life: duration })
  }
}
