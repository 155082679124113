// declare global {
//   interface Array<T> {
//     removeFirst(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): boolean
//   }
// }
// Array.prototype.removeFirst = function (predicate: (value: any, index: number, obj: any[]) => unknown) {
//   const index = (this as any).findIndex(predicate)
//   if (index >= 0) {
//     this.splice(index, 1)
//     return true
//   }
//   return false
// }

import { Component, inject, OnInit } from "@angular/core"
import { RouterOutlet } from "@angular/router"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { ToastModule } from "primeng/toast"
import { MenubarModule } from "primeng/menubar"
import { ConfirmDialogModule } from "primeng/confirmdialog"
import { Utils } from "@services/utils"
import { environment } from "../environments/environment"

@Component({
  selector: "app-root",
  standalone: true,
  imports: [RouterOutlet, ToastModule, MenubarModule, ConfirmDialogModule],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit {
  firestore = inject(AngularFirestore)

  constructor() {
    Utils.init()
  }

  create(cmd: any): Promise<any> {
    const collectionKey = "test"

    return this.firestore
      .collection(collectionKey)
      .add({ ...cmd })
      .then((item: any) => {
        return item
      })
      .catch((err) => this.handleError(err, null))
  }

  handleError(err: any, a: any) {
    console.error(err)
  }

  ngOnInit() {
    if (environment.production) gtag("config", environment.ga.trackingId)
  }
}
