import { Component, OnInit } from "@angular/core"
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { ReferentialDTO, ReferentialService } from "../referential.service"
import { ActivatedRoute, Router } from "@angular/router"
import { NotifService } from "@services/notif.service"
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog"
import { TableHeader } from "@components/list/list.model"
import { Utils } from "../../../../utils"
import { ProgressSpinnerModule } from "primeng/progressspinner"
import { CommonModule, NgFor, NgIf, NgSwitch } from "@angular/common"
import { ColorPickerModule } from "primeng/colorpicker"
import { CheckboxModule } from "primeng/checkbox"
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations"
import { InputTextModule } from "primeng/inputtext"
import { ButtonModule } from "primeng/button"

@Component({
  selector: "app-referential-edit",
  templateUrl: "./referential-edit.component.html",
  styleUrls: ["./referential-edit.component.scss"],
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ProgressSpinnerModule,
    NgSwitch,
    ColorPickerModule,
    CheckboxModule,
    NgIf,
    NgFor,
    CommonModule,
    InputTextModule,
    ButtonModule,
  ],
})
export class ReferentialEditComponent implements OnInit {
  form!: UntypedFormGroup
  referential?: ReferentialDTO
  processing = false
  fields: Array<TableHeader> = []

  constructor(
    private route: ActivatedRoute,
    private referentialService: ReferentialService,
    private formBuilder: UntypedFormBuilder,
    private notifService: NotifService,
    private router: Router,
    private dialogRef: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig
  ) {}

  ngOnInit(): void {
    this.referential = this.dialogConfig.data?.referential
    this.fields = this.dialogConfig.data?.fields

    const config = {
      name: [undefined, [Validators.required]],
    }
    this.fields.forEach((field) => {
      //@ts-ignore
      if (!config[field.field]) {
        //@ts-ignore
        config[field.field] = field.field === "color" ? ["black", undefined] : [undefined, undefined]
      }
    })
    this.form = this.formBuilder.group(config)

    if (this.referential?.id) {
      this.referentialService.fireStoreService.get(this.referential.id).then((referential) => {
        this.referential = referential
        const patch = {}
        for (const key in referential) {
          if (key !== "id" && this.referential) {
            //@ts-ignore
            patch[key] = (this.referential as any)[key]
          }
        }
        this.form.patchValue(patch)
      })
    }
  }

  save() {
    if (this.processing) {
      return
    }
    if (this.form.valid) {
      this.processing = true
      if (this.referential?.id) {
        this.referentialService.fireStoreService
          .update({
            id: this.referential.id,
            ...this.form.value,
          })
          .then((referential) => {
            this.notifService.displaySuccess("Element mis à jour")
            this.dialogRef.close(referential)
          })
          .finally(() => (this.processing = false))
      } else {
        this.referentialService.fireStoreService
          .create(this.form.value, this.formatToUniqueKey(this.form.value.name))
          .then((referential) => {
            if (referential) {
              this.dialogRef.close(referential)
            }
          })
          .finally(() => (this.processing = false))
      }
    } else {
      Utils.showFormErrors(this.form)
    }
  }

  formatToUniqueKey(str: string): string {
    return str
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") //Removing accent
      .replace(/[ \/]/g, "_")
  }
}
