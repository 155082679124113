import { inject, Injectable } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/compat/auth"
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "@angular/fire/auth"
import { NotifService } from "@services/notif.service"
import { ActivatedRoute, Router } from "@angular/router"
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "@angular/fire/functions"
import { Utils } from "@services/utils"

@Injectable({
  providedIn: "root",
})
export class AuthService {
  notifService = inject(NotifService)
  router = inject(Router)
  route = inject(ActivatedRoute)
  sendMagicLinkCF = httpsCallable(getFunctions(), "sendMagicLink")

  constructor(private angularFireAuth: AngularFireAuth) {
    // connectFunctionsEmulator(getFunctions(), "localhost", 5001) // for debugging cloudfunction locally
  }

  sendSignInLinkToEmail(email: string, programId: string): Promise<any> {
    return this.sendMagicLinkCF({
      email,
      programId: programId,
    })
      .then(async (args: any) => {
        const data = args.data
        console.log("sendSignInLinkToEmail", data)
        if (!data.status) {
          throw new Error("Unexpected error1")
        }
        await Utils.wait$(1000)
        this.notifService.displaySuccess(`Un mail vient d'être envoyé à '${email}'`)
        window.localStorage.setItem("emailForSignIn", email)
        return true
      })
      .catch((err: any) => {
        console.error("Error-sendSignInLinkToEmail", err)
        this.notifService.displayError("Impossible d'effectuer cette action")
        return false
      })
  }

  sendSignInLinkToEmail2(email: string): Promise<any> {
    return this.angularFireAuth
      .sendSignInLinkToEmail(email, {
        url: "http://localhost:4200/signIn",
        handleCodeInApp: true,
      })
      .then((args: any) => {
        console.log("sendSignInLinkToEmail2", args)
        this.notifService.displaySuccess("Un mail vient de vous être envoyé")
        window.localStorage.setItem("emailForSignIn", email)
      })
      .catch((err: any) => {
        console.error("Error-sendSignInLinkToEmail2", err)
        this.notifService.displayError("Impossible d'effectuer cette action")
      })
  }

  async confirmSignIn(): Promise<boolean> {
    await Utils.wait$(500)
    const auth = getAuth()
    if (auth.currentUser?.email) {
      // Already connected!
      const programId = this.route.snapshot?.queryParams["programId"] || ""
      this.router.navigateByUrl(`/program/${programId}`)
      return true
    }

    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem("emailForSignIn")
      if (!email) {
        // User opened the link on a different device.
        email = window.prompt("Merci de fournir l'email utilisé pour obtenir ce lien et pouvoir accéder au contenu")
      }
      // The client SDK will parse the code from the link for you.
      //@ts-ignore
      return signInWithEmailLink(auth, email, window.location.href)
        .then((result: any) => {
          console.log("LogIn", result)
          // Clear email from storage.
          window.localStorage.removeItem("emailForSignIn")
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser

          const programId = this.route.snapshot?.queryParams["programId"] || ""
          this.router.navigateByUrl(`/program/${programId}`)
          // this.notifService.displaySuccess("Vous êtes connecté")
          return true
        })
        .catch((error) => {
          let message = "Impossible de se connecter, veuillez recommencer. " + error.code
          if (error.code === "auth/invalid-email") {
            message = "Le mail saisi est invalide"
          }
          if (error.code === "auth/invalid-action-code") {
            message = "Le lien est expiré"
          }
          this.notifService.displayError(message)
          console.error("confirmSignIn", error.code)
          return false
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        })
    }
    return Promise.resolve(false)
  }

  getCurrentUser() {
    const auth = getAuth()
    return auth.currentUser
  }

  async signOut() {
    const auth = getAuth()
    await auth.signOut()
  }
}
