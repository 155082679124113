import { UntypedFormGroup } from "@angular/forms"

declare global {
  interface Array<T> {
    removeFirst(predicate: (value: T, index: number, obj: T[]) => unknown, thisArg?: any): boolean
  }
}

export class Utils {
  public static debounce(callback: () => void, wait = 500) {
    let timeoutId: number
    return () => {
      // console.log("every calls")
      window.clearTimeout(timeoutId)
      timeoutId = window.setTimeout(() => {
        // console.log("debounce called")
        callback()
      }, wait)
    }
  }

  public static isMobile = () => {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
  }

  public static formatStringFullText(str: string): string {
    return str
      .toUpperCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "") //Removing accent
      .replace(/[ \/]/g, "_")
  }

  static init() {
    Array.prototype.removeFirst = function (predicate: (value: any, index: number, obj: any[]) => unknown) {
      const index = (this as any).findIndex(predicate)
      if (index >= 0) {
        this.splice(index, 1)
        return true
      }
      return false
    }
  }

  static showFormErrors(fg: UntypedFormGroup): void {
    fg.markAsDirty()
    fg.updateValueAndValidity()
    ;(fg as any)._forEachChild((control: any) => {
      if (control instanceof UntypedFormGroup) {
        this.showFormErrors(control)
      } else {
        control.markAsDirty()
        control.updateValueAndValidity()
      }
    })
  }
}
