<div class="g_title mt-3 text-center" (click)="debug($event)">Liste des programmes</div>
@if(currentProgram){
<div class="text-xs font-italic mb-2 text-green-500">Programme en cours:
  {{ currentProgram.name }} ~ {{ currentProgram.date }} ~ {{currentProgram.town}}</div>
}


<div class="mt-6 text-center">
  @for (program of programs; track program.id) {
    <div [routerLink]="'/admin/program/' + program.id" class="cursor-pointer">
      <i class="pi pi-pencil mr-3"
      ></i> {{ program.name }} ~ {{ program.date }} ~ {{program.town}}
    </div>
    <div class="mt-2 mb-2">-</div>
  }
</div>

@if (showCreate) {
  <div class="mt-6 text-center">
    <p-button label="Créer un programe" [routerLink]="'/admin/program'"></p-button>
  </div>
}
