@if (subSection) {
  <div class="flex flex-column gap-2  mb-3 container">
    <div class="mt-1" style="display: grid;grid-template-columns: 10% 38% 4% 10% 38%;grid-row-gap: 10px">
      <!--      row1-->
      <div class="flex align-items-center">Nom interne</div>
      <input [(ngModel)]="subSection.title_internal" required pInputText/>
      <div></div>
      <div class="flex align-items-center">Catégorie</div>
      <app-dropdown-firestore
        [(ngModel)]="subSection.category"
        [referentialKey]="Referential.category_subsection"
        [required]="true"
      ></app-dropdown-firestore>
      <!--      row2-->
      <div class="flex align-items-center">Versions</div>
      <p-dropdown
        [(ngModel)]=currentVersionId
        [options]="subSection.versions"
        placeholder="Selectionner une version"
        optionValue="id"
        optionLabel="title"
        [showClear]="true"
        (onChange)="versionChange()"
      ></p-dropdown>
      <div></div>
      <div class="flex align-items-center">Nom de version</div>
      @if (currentVersionIndex >= 0 && subSection.versions[currentVersionIndex]) {
        <input [(ngModel)]="subSection.versions[currentVersionIndex].title" required pInputText
               (ngModelChange)="versionTitleChange()"/>
      }
    </div>
    <div class="mt-1">
      @if (currentVersionIndex >= 0 && subSection.versions[currentVersionIndex]) {
        <app-editor2 [(ngModel)]="subSection.versions[currentVersionIndex].content"
                     [storageId]="subSection.id || '??'"></app-editor2>
      }
    </div>

    <div class="mt-2 mb-2 flex justify-content-around">
      <p-button severity="secondary" label="Nouvelle version [depuis version actuelle]"
                (click)="newVersion(false)"></p-button>
      <p-button label="Enregistrer la sous-section" (click)="saveVersion()"></p-button>
      <p-button severity="secondary" label="Nouvelle version [vide]" (click)="newVersion()"></p-button>
    </div>
  </div>
}
