<div class="content">
  <p-table
    #table
    [stateKey]="storageKey ? 'data-table-state-' + storageKey : ''"
    [stateStorage]="stateStorage"
    [value]="tableData.values"
    [loading]="loading"
    [paginator]="!infiniteScroll"
    [rows]="rows"
    [totalRecords]="totalRecords"
    [sortOrder]="-1"
    sortMode="multiple"
    [resizableColumns]="true"
    [lazy]="lazy"
    (onLazyLoad)="loadNextPage($any($event))"
    [rowsPerPageOptions]="rowsPerPageOptions"
    currentPageReportTemplate="{currentPage} sur {totalPages} page(s) - {totalRecords} éléments"
    [showCurrentPageReport]="true"
    styleClass="p-datatable-sm p-datatable-striped"
    [dataKey]="dataKey"
    [scrollable]="infiniteScroll"
    [scrollHeight]="infiniteScroll ? scrollHeight : ''"
    [globalFilterFields]="globalFilterFields"
    [reorderableColumns]="true"
    [columns]="data.headers"
  >
    <ng-template pTemplate="caption">
      <div class="p-d-flex">
        <span class="p-input-icon-left p-input-icon-right">
          <i class="pi pi-search cursor-pointer"></i>
          <input
            pInputText
            type="text"
            placeholder="Rechercher..."
            class="p-inputtext p-component"
            [(ngModel)]="inputValue"
            (input)="table.filterGlobal($any($event.target).value, 'contains')"
          />
          <i class="pi pi-times cursor-pointer" (click)="inputValue = ''; table.filterGlobal('', 'contains')"></i>
        </span>
        <ng-content></ng-content>
        <p-button
          icon="pi pi-arrows-h"
          class="ml-2"
          (click)="expandAll()"
          *ngIf="showExpandAll"
          label=""
          title="Réinitialiser la largeur des colonnes"
        ></p-button>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngIf="expandable" style="width: 50px"></th>
        <th pResizableColumn>Actions</th>
        <ng-container *ngFor="let header of columns">
          <th pReorderableColumn *ngIf="header.sort" pResizableColumn [pSortableColumn]="getSortField(header)">
            {{ header.header }}
            <p-sortIcon *ngIf="header.sort" [field]="getSortField(header)"></p-sortIcon>
            <!-- todo why "p-sortable-column-badge" is no shown? -->
          </th>
          <th pReorderableColumn *ngIf="!header.sort" pResizableColumn>
            {{ header.header }}
          </th>
        </ng-container>
      </tr>
      <tr *ngIf="filtering">
        <th></th>
        <ng-container *ngFor="let header of columns">
          <th *ngIf="['multi', 'in'].includes(header.type)">
            <p-columnFilter [field]="header.field" [matchMode]="header.type" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-multiSelect
                  [ngModel]="value"
                  [options]="mapFilteringOptions[header.field]"
                  placeholder="Tous"
                  appendTo="body"
                  (onChange)="filter($any($event).value)"
                  optionValue="refId"
                  optionLabel="name"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="p-multiselect-representative-option">
                      {{ option.name }}
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
          </th>
          <th *ngIf="!['multi', 'in'].includes(header.type)" [class]="header.type">
            <p-columnFilter [type]="header.type || 'text'" [field]="header.field"></p-columnFilter>
          </th>
        </ng-container>
        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item let-ri="rowIndex" let-expanded="expanded" let-columns="columns">
      <tr [ngClass]="{ selected: item.id == selectedRow, 'row-colorizing': colorizingRow(item) }">
        <td class="actions">
          <ng-container *ngFor="let action of tableData.actions">
            <p-button
              [title]="action.label || ''"
              *ngIf="action.isVisible ? action.isVisible(item) : true"
              type="button"
              [icon]="action.icon + ' pi'"
              (click)="onActionClick(action.id, item, ri)"
              styleClass="p-button-text"
            ></p-button>
          </ng-container>
        </td>
        <td *ngIf="expandable">
          <button
            type="button"
            pButton
            pRipple
            [pRowToggler]="item"
            class="p-button-text p-button-rounded p-button-plain"
            [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
          ></button>
        </td>

        <ng-container *ngIf="!expandable; else expandableTemplate">
          <td
            *ngFor="let header of columns"
            [title]="item[header.field]"
            [style]="getStyle(header, item[header.field], item[header.field + '_color'])"
          >
            {{ header.pipeDate ? (item[header.field] | date : header.pipeDate) : item[header.field] }}
          </td>
        </ng-container>
        <ng-template #expandableTemplate>
          <td
            *ngFor="let header of columns"
            [title]="item[header.field]"
            [style]="header.field === 'color' ? 'color:' + item[header.field] : ''"
            [pRowToggler]="item"
            style="cursor: pointer"
          >
            {{ header.pipeDate ? (item[header.field] | date : header.pipeDate) : item[header.field] }}
          </td>
        </ng-template>
      </tr>
      <div
        *ngIf="infiniteScroll && ri === tableData.values.length - 1"
        style="width: 100%; height: 1px"
        (appDeferLoad)="showMore()"
      >
        &nbsp;
      </div>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-item>
      <ng-container *ngTemplateOutlet="expansionRowTemplate; context: { $implicit: item }"></ng-container>
    </ng-template>
  </p-table>
</div>
