import { Component, inject, OnInit } from "@angular/core"
import { ButtonModule } from "primeng/button"
import { AuthService } from "@services/auth.service"
import { InputTextModule } from "primeng/inputtext"
import { FormsModule } from "@angular/forms"
import { ProgramPubDTO, ProgramPublishedService } from "@services/program.published.service"
import { SubSectionComponent } from "../program/sub-section/sub-section.component"
import { InfoDetailComponent } from "../program/info-detail/info-detail.component"
import { ProgressSpinnerModule } from "primeng/progressspinner"
import { Router } from "@angular/router"

@Component({
  selector: "app-home",
  standalone: true,
  imports: [
    ButtonModule,
    InputTextModule,
    FormsModule,
    SubSectionComponent,
    InfoDetailComponent,
    ProgressSpinnerModule,
  ],
  templateUrl: "./home.component.html",
  styleUrl: "./home.component.scss",
})
export class HomeComponent implements OnInit {
  authService = inject(AuthService)
  programPubService = inject(ProgramPublishedService)
  router = inject(Router)

  loaded = false
  submitting = false
  sent = false
  email = ""
  program: ProgramPubDTO | undefined = {
    id: "abc",
    version: ".",
    name: "...",
    date: ".. ... ....",
    town: "...",
    disabledMessage: "",
    intro: {
      title: "...",
      content: ". ".repeat(1200),
      id: "123",
    },
    sections: [],
    date_published: new Date(),
    accessible: true,
  }
  currentUser: any

  async ngOnInit() {
    this.program = await this.programPubService.getCurrentProgramNotConnected()
    this.loaded = true
    this.currentUser = this.authService.getCurrentUser()
    if (this.currentUser?.email && this.program) {
      // If the user is connected => redirecting on the current program
      this.router.navigateByUrl("/program/" + this.program.id)
    }
  }

  async send() {
    this.submitting = true
    const status = await this.authService.sendSignInLinkToEmail(this.email, this.program?.id || "")
    if (status) {
      this.sent = true
    }
    this.submitting = false
  }

  async signOut() {
    this.authService.signOut()
    this.currentUser = this.authService.getCurrentUser()
  }

  async goToProgram() {
    const program = await this.programPubService.getCurrentProgramNotConnected()
    if (program) {
      await this.router.navigateByUrl("program/" + program.id)
    }
  }
}
