import { Component, inject, OnInit } from "@angular/core"
import { AuthService } from "@services/auth.service"
import { Router } from "@angular/router"

@Component({
  selector: "app-sign-in",
  standalone: true,
  imports: [],
  templateUrl: "./sign-in.component.html",
  styleUrl: "./sign-in.component.scss",
})
export class SignInComponent implements OnInit {
  authService = inject(AuthService)
  router = inject(Router)
  async ngOnInit() {
    const success = await this.authService.confirmSignIn()
    if (!success) {
      this.router.navigateByUrl("/")
    }
  }
}
