import { inject, Injectable } from "@angular/core"
import { FireStoreService } from "@services/fire-store.service"
import { SubSectionService } from "@services/sub-section.service"
import {
  ProgramPubDTO,
  ProgramPublishedService,
  SectionPubDTO,
  SubSectionPubDTO,
} from "@services/program.published.service"

export interface SectionDTO {
  removing?: boolean // front only
  id?: string
  title: string
  title_internal: string
  subsectionsIds: Array<string>

  subsectionsRefs: Array<{
    id: string
    versionId: string
  }>
}

export interface ProgramDTO {
  id?: string
  version: string

  email: {
    subject: string
    content: string
  }
  disabledMessage: string
  name: string
  town: string
  date: string
  accessible: boolean
  date_real?: Date
  introductionId: string
  sections: Array<SectionDTO>
}

@Injectable({
  providedIn: "root",
})
export class ProgramService extends FireStoreService<ProgramDTO> {
  subSectionService = inject(SubSectionService)
  programPublishedService = inject(ProgramPublishedService)

  constructor() {
    super()
    this.init({
      collectionKey: `program`,
      fullTextFields: [],
    })
  }

  override beforeSavingHook(program: ProgramDTO): ProgramDTO {
    program?.sections?.forEach((section) => {
      if (!section.id || section.id.startsWith("RANDOM")) {
        section.id = this.uuid()
      }
    })
    return program
  }

  async convertSection(section: SectionDTO): Promise<SectionPubDTO> {
    //@ts-ignore
    const subSections = await Promise.all(
      section.subsectionsIds?.map(async (id) => {
        const sub = await this.subSectionService.get(id)
        return {
          id: sub.id || "",
          title: sub.title || "",
          content: sub.content || "",
        }
      })
    )
    //@ts-ignore
    return {
      id: section.id || "",
      title: section.title || "",
      subSections: subSections || [],
    }
  }

  async publish(programId: string): Promise<any> {
    try {
      const program = await this.get(programId)
      if (!program) return
      let intro = {} as any
      if (program.introductionId) {
        intro = await this.subSectionService.get(program.introductionId)
      }

      const programPublished: ProgramPubDTO = {
        id: programId,
        name: program.name,
        date: program.date,
        town: program.town,
        version: "1111",
        intro,
        sections: [],
        date_published: new Date(),
        accessible: program.accessible,
        disabledMessage: program.disabledMessage || "",
      }
      let sections: Array<SectionPubDTO> = []
      if (program?.sections) {
        //@ts-ignore
        sections = await Promise.all(program?.sections.map(async (section) => this.convertSection(section)))
      }

      const programAlreadyPublished = await this.programPublishedService.get(programId)
      if (programAlreadyPublished) {
        await this.programPublishedService.update(programPublished)
      } else {
        await this.programPublishedService.create(programPublished, programId)
      }
      await this.programPublishedService.addSection(programId, sections)
      await this.programPublishedService.saveCurrentProgramId(programId)
    } catch (e) {
      this.handleError(e, "")
    }
  }
}
