import { inject, Injectable } from "@angular/core"
import { getStorage, ref, uploadBytes } from "firebase/storage"
import { getDownloadURL, listAll } from "@angular/fire/storage"
import { NotifService } from "@services/notif.service"

export interface ImagesStorage {
  name: string
  src: string
}

@Injectable({
  providedIn: "root",
})
export class StorageService {
  notifService = inject(NotifService)

  constructor() {}

  async getImages(programId: string): Promise<Array<ImagesStorage>> {
    const images: Array<ImagesStorage> = []
    const storage = getStorage()
    const storageRef = ref(storage, programId)
    await listAll(storageRef)
      .then((res) => {
        res.prefixes.forEach((folderRef) => {
          // All the prefixes under listRef.
          // You may call listAll() recursively on them.
          console.log("folderRef", folderRef)
        })
        res.items.forEach(async (itemRef) => {
          // All the items under listRef.
          const url = await getDownloadURL(itemRef)
          console.log("itemRef", itemRef, url)
          images.push({
            name: itemRef.name,
            src: url,
          })
        })
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        debugger
      })
    return images
  }

  async uploadFile(file: any, programId: string): Promise<ImagesStorage | null> {
    const storage = getStorage()
    const storageRef = ref(storage, `${programId}/` + `${Math.random()}_${Date.now()}`) // avoiding collisions
    let result = null
    await uploadBytes(storageRef, file)
      .then(async (snapshot) => {
        //https://www.sentinelstand.com/article/guide-to-firebase-storage-download-urls-tokens
        const url = await getDownloadURL(snapshot.ref)
        this.notifService.displaySuccess("Image uploadée")
        result = {
          name: file.name,
          src: url,
        }
      })
      .catch((err: any) => {
        this.notifService.displayError(err.message)
      })
    return result
  }
}
