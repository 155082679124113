<p-dialog
  [(visible)]="showPreviewPopup"
  [modal]="true"
  class="preview"
  [header]="'Aperçu de la section \'' + _section?.title + '\''"
  [style]="{ width: '100vw', height: '100vh'}"
>
  <div class="content flex justify-content-center">
    <div style="max-width: 500px">
      <h1 class="title mb-3">{{ sectionPreview?.title }}</h1>
      @for (subSection of sectionPreview?.subSections; track subSection.id) {
        <app-sub-section [subSection]="subSection"></app-sub-section>
        <div class="separator2"></div>
      }
    </div>
  </div>

  <div class="ui-dialog-footer text-center mt-4">
    <p-button (click)="showPreviewPopup = false">Fermer la section</p-button>
  </div>
</p-dialog>

@if (_section) {

  <div class="container mb-3 relative" [class]="{'notExpanded': !expanded}">
    <div class="absolute" style="right:0">
      <i class="cursor-pointer pi pi-arrow-up ml-4 mr-2"
         (click)="onMove.emit(true)"></i>
      <i class="cursor-pointer pi pi-arrow-down mr-2"
         (click)="onMove.emit(false)"></i>
      <i class="cursor-pointer pi pi-trash ml-4 mr-2" (click)="onDelete.emit()"></i>
      <i class="cursor-pointer pi pi-eye ml-4 mr-2" (click)="updatePreview(_section);showPreviewPopup = true"></i>
      @if (expanded) {
        <i class="cursor-pointer pi pi-chevron-up ml-4 mr-2 opacity-50"
           (click)="toggleExpand(false)"></i>
      } @else {
        <i class="cursor-pointer pi pi-chevron-down ml-4 mr-2 opacity-50"
           (click)="toggleExpand(true)"></i>
      }
    </div>
    <div class="flex flex-column gap-2">
      <label for="title">Nom de la section *</label>
      <input required [(ngModel)]="_section.title"
             pInputText id="title"/>
      @if (!expanded) {
        <div class="text-gray-400">Note: {{ _section.title_internal }}</div>
      }
      <label for="note">Nom interne</label>
      <input required [(ngModel)]="_section.title_internal"
             pInputText id="note"/>
    </div>
    <div class="g_title mt-4 mb-1 flex align-items-center">
      Sous-sections
      <div class="ml-6">
        <i title="Ajouter une sous-section" class="pi pi-plus cursor-pointer"
           (click)="addSubSection()"></i>
      </div>
    </div>
    <p-pickList [source]="source" [target]="_section.subsectionsIds"
                sourceHeader="Disponibles" targetHeader="Sélectionnées" [dragdrop]="true"
                [responsive]="true"
                [sourceStyle]="{ height: '300px' }" [targetStyle]="{ height: '300px' }" breakpoint="900px">
      <ng-template let-id pTemplate="item">
        <div class="flex relative">
          {{ mapSubSections[id]?.title_internal }}
          <span class="ml-2 opacity-50">[{{ mapSubSections[id]?.title }}]</span>
          <i class="pi pi-pencil cursor-pointer ml-auto"
             (click)="openSubSectionDetails(id); $event.stopPropagation()"></i>
        </div>
      </ng-template>
    </p-pickList>
  </div>
}
